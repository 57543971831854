import callApi from '../../util/apiCaller';

export function register(name, email, password, info) {
  return callApi('user', 'post', { name, email, password, info });
}

export function login(email, password) {
  return callApi('user/login', 'post', { email, password });
}

export function logout() {
  return callApi('user/logout');
}

export function me() {
  return callApi('user/me');
}

export function getUserById(userId) {
  return callApi('user/' + userId);
}

export function update(name, password, info) {
  return callApi('user/me', 'put', { name, password, info });
}

export function verify(tokenId) {
  return callApi('user/verify', 'post', { tokenId });
}

export function resendVerify(email) {
  return callApi('user/resendVerify', 'post', { email });
}

export function forgotPassword(email) {
  return callApi('user/reset', 'post', { email });
}

export function resetPassword(tokenId, password) {
  return callApi('user/verify', 'post', { tokenId, password });
}

export function listUsers() {
  return callApi('user');
}

export function getMyInvites() {
  return callApi('user/me/invites');
}
