/* eslint class-methods-use-this: 0 */

const API_URL = 'https://hslocalapi.internal.isw.net.au';

const config = {
  services: new (class {
    get api() {
      if (window.config && window.config.core) {
        return window.config.core;
      }
      console.warn(`Using default API URL: ${API_URL}`);
      return API_URL;
    }
  })(),
  limits: {
    commentLimit: 50, //the limit of comments to get for an idea with each service call using pagination/infinite scroll technique
  },
};

export default config;
