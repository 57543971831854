import { useCallback } from 'react';
import { TextField } from 'react-md';

import BoardsURLInput from './BoardsURLInput';
import VerseURLInput from './VerseURLInput';

/** @typedef {import('../../OrgAPI').Org} Org */
/**
 * @param {{ orgs: import('../../OrgAPI').Org[],  boardsURL?:string, authPath?: string, verseURL?:string,  onChange: (value: { boardsURL?: string, authPath?: string, verseURL?: string }) => void }} props
 */
export default function FormInputs({
  boardsURL,
  authPath,
  verseURL,
  onChange,
}) {
  return (
    <>
      <BoardsURLInput
        value={boardsURL}
        onChange={useCallback(
          (/** @type {string} */ boardsURL) => {
            onChange({ boardsURL, authPath, verseURL });
          },
          [authPath, onChange, verseURL]
        )}
      />

      <TextField
        label="Boards Auth Path"
        id={'boards-url'}
        value={authPath}
        disabled={!boardsURL?.endsWith('huddo.com')}
        onChange={useCallback(
          (/** @type {string|number} */ value) => {
            const authPath = value ? `${value}` : '';
            onChange({ boardsURL, authPath, verseURL });
          },
          [boardsURL, onChange, verseURL]
        )}
      />
      <VerseURLInput
        className="bext-verse"
        value={verseURL}
        onChange={useCallback(
          (verseURL) => {
            onChange({ boardsURL, authPath, verseURL });
          },
          [authPath, boardsURL, onChange]
        )}
      />
    </>
  );
}
