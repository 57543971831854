import PropTypes from 'prop-types';
import { Component } from 'react';
import { TextField, Button, Subheader } from 'react-md';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { login } from '../UserActions';

class Login extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {
    email: '',
    password: '',
    emailError: false,
    emailErrorMsg: 'Email is required',
    passwordError: false,
    passwordErrorMsg: 'Password is required',
    submitError: false,
  };
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }
  login(e) {
    e.preventDefault();
    const valid = this.validateForm();
    if (valid) {
      const { email, password } = this.state;

      this.props.dispatch(login(email, password)).catch(() => {
        this.setState({ submitError: true });
      });
    }
  }
  validateEmail() {
    if (!this.state.email) {
      this.setState({
        emailError: true,
        emailErrorMsg: 'Your email is required',
      });
      return false;
    }
    this.setState({ emailError: false, emailErrorMsg: '' });
    return true;
  }
  validatePassword() {
    if (!this.state.password) {
      this.setState({
        passwordError: true,
        passwordErrorMsg: 'Password is required',
      });
      return false;
    }
    this.setState({ passwordError: false, passwordErrorMsg: '' });
    return true;
  }

  validateForm() {
    const nameEmail = this.validateEmail();
    const namePassword = this.validatePassword();

    return nameEmail && namePassword;
  }

  render() {
    const { email, password, submitError } = this.state;

    return (
      <div>
        <h1>Welcome to the Huddo Store</h1>
        Huddo Boards Premium View lets you enhance your Activities Plus work
        space. View your Activities as Boards, MindMaps, Timelines and drive
        collaborative team work. <br />
        Enable Huddo Boards Premium View for your organisation today. Login or{' '}
        <Link to="/register">Register</Link> to get started.
        <br />
        <br />
        <Subheader primary component="div" primaryText="Login" />
        <form onSubmit={this.login}>
          <TextField
            id="floating-center-title"
            label="Email"
            type="email"
            lineDirection="center"
            required
            error={this.state.emailError}
            errorText={this.state.emailErrorMsg}
            value={email}
            onChange={(email) => this.setState({ email })}
          />
          <TextField
            id="floating-password"
            label="Enter your password"
            type="password"
            required
            error={this.state.passwordError}
            errorText={this.state.passwordErrorMsg}
            value={password}
            onChange={(password) => this.setState({ password })}
          />
          <div
            id="errorMsg"
            style={{ color: 'red' }}
            className={submitError ? 'ks-show' : 'ks-hide'}
          >
            Email and/or password not valid
          </div>
          <Button
            primary
            raised
            type="submit"
            //onClick={this.login}
            children="Login"
          />
        </form>
        <Link to="/user/reset">Forgot Password</Link>{' '}
        <Link to="/register">Register</Link>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

// function _mapStateToProps(state) {
//   const { user } = state; // const user = state.user
//   return { user };
//   // return {
//     // user: user,
//   // };
// }
// // const mapStateToProps = _mapStateToProps.bind(this);

export default connect(mapStateToProps)(Login);
