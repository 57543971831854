import { useCallback } from 'react';
import { TextField } from 'react-md';

import placeholders from './placeholders';
import { useURLChangeHandler } from './useURLChangeHandler';

/**
 * @param {{className?:string, value?:string, disabled?: boolean, onChange?: (value:string) => void}} props
 */
const BoardsURLInput = ({
  className = '',
  value,
  disabled = false,
  onChange = () => undefined,
}) => {
  const setValue = useURLChangeHandler(onChange);

  return (
    <TextField
      disabled={disabled}
      label="Boards URL"
      id={'boards-url'}
      className={`${className}`}
      helpText={`e.g. ${placeholders.boardsURL}`}
      value={value}
      onChange={useCallback(
        (/** @type {string|number} */ value) => {
          setValue(value ? `${value}` : '');
        },
        [setValue]
      )}
    />
  );
};

export default BoardsURLInput;
