import { combineReducers } from 'redux';

import invites from './modules/User/InviteReducer';
import licences from './modules/User/LicencesReducer';
import orgadmins from './modules/User/OrgAdminReducer';
import orgs from './modules/User/OrgsReducer';
import user from './modules/User/UserReducer';
import users from './modules/User/UsersReducer';

export default combineReducers({
  user,
  users,
  invites,
  orgs,
  orgadmins,
  licences,
});
