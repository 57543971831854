import React from 'react';
import { Button, Checkbox, DialogContainer } from 'react-md';

/**
 * @typedef {{ visible: boolean, url:string, onClose: ()=>void }} DownloadLicenceDialogProps
 *  @type {(props: DownloadLicenceDialogProps) => JSX.Element}
 */
function DownloadLicenceDialog({ visible, url, onClose }) {
  const [agreed, setAgreed] = React.useState(false);

  return (
    <DialogContainer
      className="dl-licence-dialog"
      id="simple-action-dialog"
      visible={visible}
      onHide={onClose}
      actions={[
        <Button flat primary onClick={onClose}>
          {'Cancel'}
        </Button>,
        <Button
          flat
          primary
          href={url}
          disabled={!agreed}
          onClick={(e) => {
            if (agreed) onClose();
            else {
              e.preventDefault(); // don't open the url
            }
          }}
        >
          {'Download'}
        </Button>,
      ]}
      title="Terms of Use"
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          // weird bug is causing mis-clicking...
          window.open(
            'https://www.huddo.com/terms-of-use',
            '_blank',
            'noopener,noreferrer'
          );
        }}
      >
        <p>
          To download this licence, you must agree to the{' '}
          <a
            href={'https://www.huddo.com/terms-of-use'}
            target={'_blank'}
            rel="noreferrer"
          >
            Terms of Use
          </a>
          .
        </p>
        <Checkbox
          checked={agreed}
          id="agreed"
          name="agreed"
          onChange={(checked) => setAgreed(checked)}
          label="I agree."
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </div>
    </DialogContainer>
  );
}
export default DownloadLicenceDialog;
