import PropTypes from 'prop-types';
import { Component } from 'react';
import { Button, SelectField, DatePicker, TextField } from 'react-md';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { createLicence } from '../LicenceActions';
import { getOrgs } from '../OrgActions';

const productNames = [
  {
    label: 'ACTIVITIES+',
    value: 'com.kudosapps.boards.lite',
  },
  {
    label: 'BOARDS PREMIUM',
    value: 'com.kudosapps.boards.premium',
  },
];

const licenceType = [
  {
    label: 'Named',
    value: 'named',
  },
  {
    label: 'Number',
    value: 'number',
  },
];

class CreateLicence extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {
    productID: 'com.kudosapps.boards.lite',
    type: 'number',
    expiry: '',
    limit: '',
    restrictions: '',
    orgId: '',
    customisations: '',
    redirect: '',
    orgError: false,
    orgErrorMsg: '',
    productError: false,
    productErrorMsg: '',
    typeError: false,
    typeErrorMsg: '',
    numberUserError: false,
    numberUserErrorMsg: '',
    expiryError: false,
    expiryErrorMsg: '',
  };
  constructor(props) {
    super(props);
    this.validateForm = this.validateForm.bind(this);
    this.getOrgs = this.getOrgs.bind(this);
    this.createLicence = this.createLicence.bind(this);
    this.validateOrganisation = this.validateOrganisation.bind(this);
    this.validateProduct = this.validateProduct.bind(this);
    this.validateType = this.validateType.bind(this);
    this.validateNumberUsers = this.validateNumberUsers.bind(this);
    this.validateExpiry = this.validateExpiry.bind(this);
  }
  getOrgs() {
    const { user } = this.props;
    this.props.dispatch(getOrgs(user.isAdmin));
  }
  componentDidMount() {
    const { match } = this.props;
    this.setState({ orgId: match.params.orgId });
    this.getOrgs();
  }
  createLicence(e) {
    const { productID, orgId, expiry, type, limit } = this.state;

    if (!this.validateForm()) {
      e.preventDefault();
    } else {
      const expiryDate = new Date(expiry);
      expiryDate.setHours(23);
      expiryDate.setMinutes(59);
      expiryDate.setSeconds(59);

      this.props
        .dispatch(createLicence(productID, type, expiry, limit, orgId))
        .then(() => {
          this.setState({ redirect: '/org/' + orgId });
        });
    }
  }
  validateOrganisation() {
    if (!this.state.orgId) {
      this.setState({
        orgError: true,
        orgErrorMsg: 'Organisation is required',
      });
      return false;
    }
    this.setState({ orgError: false, orgErrorMsg: '' });
    return true;
  }
  validateProduct() {
    if (!this.state.productID) {
      this.setState({
        productError: true,
        productErrorMsg: 'Product is required',
      });
      return false;
    }
    this.setState({ productError: false, productErrorMsg: '' });
    return true;
  }
  validateType() {
    if (!this.state.type) {
      this.setState({
        typeError: true,
        typeErrorMsg: 'Licence Type is required',
      });
      return false;
    }
    this.setState({ typeError: false, typeErrorMsg: '' });
    return true;
  }
  validateNumberUsers() {
    if (!this.state.limit) {
      this.setState({
        numberUserError: true,
        numberUserErrorMsg: 'Number of Users is required',
      });
      return false;
    }
    this.setState({ numberUserError: false, numberUserErrorMsg: '' });
    return true;
  }
  validateExpiry() {
    if (!this.state.expiry) {
      this.setState({
        expiryError: true,
        expiryErrorMsg: 'Licence Type is required',
      });
      return false;
    }
    this.setState({ expiryError: false, expiryErrorMsg: '' });
    return true;
  }
  validateForm() {
    const orgCorrect = this.validateOrganisation();
    const productCorrect = this.validateProduct();
    const typeCorrect = this.validateType();
    const numUsersCorrect = this.validateNumberUsers();
    const expiryCorrect = this.validateExpiry();

    return (
      orgCorrect &&
      productCorrect &&
      typeCorrect &&
      numUsersCorrect &&
      expiryCorrect
    );
  }
  render() {
    const { productID, orgId, expiry, limit, type, redirect } = this.state;
    const { orgs, user } = this.props;

    if (!user.isAdmin) return <Redirect to="/home" />;

    if (redirect) return <Redirect to={redirect} />;

    const orgList = [];
    if (orgs) {
      Object.keys(orgs).map((_, i) =>
        orgList.push({
          label: orgs[i].name,
          value: orgs[i].id,
        })
      );
    }
    return (
      <div>
        <br />
        <SelectField
          id="ks-org"
          label="Organisation"
          fullWidth
          required
          error={this.state.orgError}
          errorText={this.state.orgErrorMsg}
          menuItems={orgList}
          value={orgId}
          onChange={(orgId) => this.setState({ orgId })}
        />
        <br />
        <SelectField
          id="ks-product"
          label="Product"
          fullWidth
          error={this.state.productError}
          errorText={this.state.productErrorMsg}
          menuItems={productNames}
          value={productID}
          onChange={(productID) => this.setState({ productID })}
        />
        <SelectField
          id="ks-type"
          label="Type"
          fullWidth
          error={this.state.typeError}
          errorText={this.state.typeErrorMsg}
          menuItems={licenceType}
          value={type}
          onChange={(type) => this.setState({ type })}
        />
        <br />
        <TextField
          id="ks-numUsers"
          label="Number of Users"
          lineDirection="center"
          error={this.state.numberUserError}
          errorText={this.state.numberUserErrorMsg}
          required={true}
          value={limit}
          onChange={(limit) => this.setState({ limit })}
          fullWidth
          type="number"
        />
        <DatePicker
          id="ks-licence-expiry"
          label="Expiry"
          displayMode="portrait"
          required
          minDate={new Date()}
          error={this.state.expiryError}
          errorText={this.state.expiryErrorMsg}
          value={expiry}
          onChange={(_, expiry) => this.setState({ expiry })}
        />
        <br />
        <Button
          primary
          raised
          onClick={this.createLicence}
          disabled={false}
          children="Create Licence"
        />
      </div>
    );
  }
}

const mapStateToProps = (
  { user, orgs },
  {
    match: {
      params: { orgId },
    },
  }
) => ({ user, org: orgs.find((org) => org._id === orgId), orgs });
export default connect(mapStateToProps)(CreateLicence);
