import callApi from '../../util/apiCaller';

export function all(orgId) {
  return callApi('/org/' + orgId + '/admin');
}

export function invite(orgId, email) {
  return callApi('/org/' + orgId + '/admin', 'post', {
    email,
  });
}

export function accept(orgId, adminId) {
  return callApi('/org/' + orgId + '/admin/' + adminId + '/accept');
}
