// import { useCallback, useEffect, useState } from 'react';
import { Button, FontIcon } from 'react-md';

import config from '../../../../config';
import placeholders from './placeholders';

/**
 * @param {{className?:string, boardsURL?:string, verseURL?:string, authPath?: string}} props
 */
const BoardsURLInput = ({ className = '', boardsURL, verseURL, authPath }) => {
  if (!boardsURL) boardsURL = placeholders.boardsURL;
  if (!verseURL) verseURL = placeholders.verseURL;
  const url = new URL(`${config.services.api}/bext`);
  url.searchParams.append('boardsURL', boardsURL);
  url.searchParams.append('verseURL', verseURL);
  if (authPath) url.searchParams.append('authPath', authPath);

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={url.href} target="_blank" className={className}>
      <Button raised primary iconEl={<FontIcon>{'download'}</FontIcon>}>
        Download
      </Button>
    </a>
  );
};

export default BoardsURLInput;
