import PropTypes from 'prop-types';
import { Component } from 'react';
import { Button, TextField, Subheader } from 'react-md';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import { verify, resendVerify, logout } from '../UserActions';

class Verify extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {
    token: '',
    redirect: '',
    verified: false,
    email: '',
    tokenError: false,
    emailResent: false,
  };

  constructor(props) {
    super(props);
    this.resendVerify = this.resendVerify.bind(this);
  }
  componentDidMount() {
    const { user } = this.props;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    this.setState({ token });
    if (token) {
      this.props
        .dispatch(verify(token))
        .then((data) => {
          if (!data.status) {
            this.setState({ verified: true });
            setTimeout(() => this.setState({ redirect: '/login' }), 3000);
            this.setState({ tokenError: false });
            if (user) {
              if (user.email !== data.email) {
                this.props.dispatch(logout());
              }
            }
          } else {
            this.setState({ token: '' });
            this.setState({ tokenError: true });
          }
        })
        .catch(() => {
          this.setState({ token: '' });
          this.setState({ tokenError: true });
        });
    }
  }
  resendVerify() {
    const { email } = this.state;

    if (!email) {
      this.setState({
        emailError: true,
        emailErrorMsg: 'Your email is required',
      });
    } else {
      this.setState({
        emailError: false,
        emailErrorMsg: '',
        emailResent: true,
      });
      this.props.dispatch(resendVerify(email));
    }
  }
  render() {
    const { user } = this.props;
    const { token, redirect, verified, tokenError, email, emailResent } =
      this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    if (user && user.verified) {
      return <Redirect to="/home" />;
    }

    if (verified) {
      return <div>Verification complete. Redirecting to login page...</div>;
    }

    if (emailResent) {
      setTimeout(() => this.setState({ redirect: '/login' }), 3000);
      return <div>Verification email sent. Redirecting to login page...</div>;
    }

    if (!token) {
      return (
        <div>
          <h1>Welcome to the Huddo Store</h1>
          Huddo Boards Premium View lets you enhance your Activities Plus work
          space. View your Activities as Boards, MindMaps, Timelines and drive
          collaborative team work. <br />
          Enable Huddo Boards Premium View for your organisation today.{' '}
          <Link to="/login">Login</Link> or <Link to="/register">Register</Link>{' '}
          to get started.
          <br />
          <br />
          <Subheader primary component="div" primaryText="Email Verification" />
          <div className={tokenError ? 'ks-hide' : 'ks-show'}>
            Account requires email verification. Please check your email.
          </div>
          <div
            style={{ color: 'red' }}
            className={tokenError ? 'ks-show' : 'ks-hide'}
          >
            Email validation failed.
          </div>
          <br />
          <TextField
            id="ks-email"
            label="Email"
            lineDirection="center"
            type="email"
            error={this.state.emailError}
            errorText={this.state.emailErrorMsg}
            value={email}
            onChange={(email) => this.setState({ email })}
            onBlur={this.validateEmail}
          />
          <Button raised primary onClick={this.resendVerify}>
            Resend verification
          </Button>
        </div>
      );
    }
    return <div>Validating email...</div>;
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(Verify);
