// import { useCallback, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Button, FontIcon } from 'react-md';

import createLink from './createLink';
import placeholders from './placeholders';

const ShellButton = ({ boardsURL, verseURL, authPath, onLinkCreated }) => {
  if (!boardsURL) boardsURL = placeholders.boardsURL;
  if (!verseURL) verseURL = placeholders.verseURL;

  const onClick = useCallback(async () => {
    const linkId = await createLink(boardsURL, verseURL, authPath);
    onLinkCreated(linkId);
  }, [boardsURL, onLinkCreated, verseURL, authPath]);

  return (
    <Button
      raised
      primary
      iconEl={<FontIcon>{'terminal'}</FontIcon>}
      onClick={onClick}
    >
      shell command
    </Button>
  );
};

export default ShellButton;
