export const clientProvider = [
  {
    label: 'HCL Connections',
    value: 'connections',
  },
  {
    label: 'HCL Domino',
    value: 'domino',
  },
  {
    label: 'HCL DX',
    value: 'dx',
  },
  {
    label: 'Microsoft 365',
    value: 'msgraph',
  },
  {
    label: 'Microsoft AD',
    value: 'msad',
  },
];

export const clientProviderView = {
  connections: 'HCL Connections',
  domino: 'HCL Domino',
  dx: 'HCL DX',
  msgraph: 'Microsoft 365',
  msad: 'Microsoft AD',
};

export const isActivitiesPlus = (provider) =>
  ['connections', 'dx'].includes(provider);
