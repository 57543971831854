import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { logout } from '../UserActions';

// class Logout extends Component {
//   static propTypes = {
//     dispatch: PropTypes.func.isRequired,
//   }
//   state = {
//   };
//   constructor(props) {
//     super(props);
//     this.logout = this.logout.bind(this);
//     this.logout();
//   }
//   logout() {
//     this.props.dispatch(logout());
//     this.props.history.push('/login');
//   }
//   render() {
//     return (
//       <div>You have been logged out. If you are not redirected, please click to <Link to="/login">Login</Link> again.
//       </div>
//     );
//   }
// }

const mapStateToProps = ({ user }) => ({ user });

// function _mapStateToProps(state) {
//   const { user } = state; // const user = state.user
//   return { user };
//   // return {
//     // user: user,
//   // };
// }
// // const mapStateToProps = _mapStateToProps.bind(this);

export default connect(mapStateToProps)(({ user, dispatch }) => {
  useEffect(() => {
    dispatch(logout());
  });
  if (user) return <div>Logout in progress......</div>;

  return <Redirect to="/login" />;
});
