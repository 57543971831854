import PropTypes from 'prop-types';
import { Component } from 'react';
import { TextField, List, ListItem, Subheader } from 'react-md';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import { getBasicDetails } from '../OrgActions';
import { getCurrentUser, updateUser, getMyInvites } from '../UserActions';

class Profile extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {
    name: '',
    password: '',
    secondPassword: '',
    canSubmit: false,
    nameError: false,
    nameErrorMsg: '',
    changePassword: false,
    changePasswordLabel: 'Change Password',
    passwordError: false,
    passwordErrorMsg: '',
    secondPasswordError: false,
    secondPasswordErrorMsg: '',
  };
  constructor(props) {
    super(props);
    this.updateUser = this.updateUser.bind(this);
    this.getBasicOrgDetails = this.getBasicOrgDetails.bind(this);
    this.getInvites = this.getInvites.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.updatePasswordToggle = this.updatePasswordToggle.bind(this);
  }
  getUser() {
    this.props.dispatch(getCurrentUser());
  }
  updateUser() {
    const { name, email, password } = this.state;
    if (!name || !email || !password) return; //show an error:

    this.props.dispatch(updateUser(name, email, password, null));
    return <Redirect to="/" />;
  }
  getBasicOrgDetails(orgId) {
    this.props.dispatch(getBasicDetails(orgId));
  }
  getInvites() {
    this.props.dispatch(getMyInvites()).then((invites) => {
      Object.keys(invites).map((_, i) =>
        this.getBasicOrgDetails(invites[i].org)
      );
    });
  }
  componentDidMount() {
    this.getUser();
    this.getInvites();
  }
  validateForm() {
    let valid = true;
    if (!this.state.name) {
      this.setState({ nameError: true, nameErrorMsg: 'Your name is required' });
      valid = false;
    } else this.setState({ nameError: false, nameErrorMsg: '' });

    if (!this.state.password) {
      this.setState({
        passwordError: true,
        passwordErrorMsg: 'This is required',
      });
      valid = false;
    } else this.setState({ passwordError: false, passwordErrorMsg: '' });

    if (!this.state.secondPassword) {
      this.setState({
        secondPasswordError: true,
        secondPasswordErrorMsg: 'This is required',
      });
      valid = false;
    } else
      this.setState({ secondPasswordError: false, secondPasswordErrorMsg: '' });

    if (this.state.password && this.state.secondPassword) {
      if (this.state.password !== this.state.secondPassword) {
        this.setState({
          secondPasswordError: true,
          secondPasswordErrorMsg: 'Passwords must match',
        });
        valid = false;
      }
    }

    this.setState({ canSubmit: valid });
  }
  updatePasswordToggle() {
    this.setState({ changePassword: !this.state.changePassword });
    if (this.state.changePassword) {
      this.setState({ changePasswordLabel: 'Change Password' });
    } else this.setState({ changePasswordLabel: 'Cancel Password Change' });
    //this.state.changePassword = !this.state.changePassword;
  }
  render() {
    //const { name, password, secondPassword} = this.state;
    const { invites, orgs } = this.props;
    const { name, email, info = {} } = this.props.user;
    let primaryLink, invitesListItems;
    if (info?.primaryOrg) primaryLink = '/org/' + info?.primaryOrg;
    else primaryLink = '/org';
    //const style = this.state.changePassword ? {} : {display: 'none'};

    if (invites && orgs.length !== 0) {
      invitesListItems = Object.keys(invites).map((_, i) => (
        <ListItem
          component={Link}
          key={i}
          to={'/org/' + invites[i].org + '/admin/' + invites[i]._id + '/accept'}
          primaryText={orgs.find((el) => el._id === invites[i].org).name}
        />
      ));
    }

    return (
      <div>
        <TextField
          id="floating-center-title"
          label="Name"
          lineDirection="center"
          disabled
          fullWidth
          error={this.state.nameError}
          errorText={this.state.nameErrorMsg}
          defaultValue={name}
          //value={name}
          //onChange={name => this.setState({ name })}
        />
        <TextField
          id="floating-center-title"
          label="Email"
          lineDirection="center"
          type="email"
          disabled
          fullWidth
          value={email}
        />
        <Link to={primaryLink}>My Organisation</Link>
        {invites.length !== 0 ? (
          <div>
            <Subheader
              primary
              component="div"
              primaryText="Organisation Admin Invites"
            />
            <List>{invitesListItems}</List>
          </div>
        ) : (
          ''
        )}
      </div>
    );
    // <Button
    //  primary
    //  raised
    //  onClick={this.updatePasswordToggle}
    //  children={this.state.changePasswordLabel}
    // />
    // <div id='ks-passwordChange' style={style}>
    //   <TextField
    //     id="floating-password"
    //     label="Enter your password"
    //     type="password"
    //     className="md-cell md-cell--bottom"
    //     error={this.state.passwordError}
    //     errorText={this.state.passwordErrorMsg}
    //     value={password}
    //     onChange={password => this.setState({ password })}
    //   />
    //   <TextField
    //     id="floating-password"
    //     label="Reenter your password"
    //     type="password"
    //     className="md-cell md-cell--bottom"
    //     error={this.state.secondPasswordError}
    //     errorText={this.state.secondPasswordErrorMsg}
    //     value={secondPassword}
    //     onChange={secondPassword => this.setState({ secondPassword })}
    //   />
    // </div>
    // <Button
    //  primary
    //  raised
    //  disabled={!this.state.canSubmit}
    //  onClick={this.updateUser}
    //  children="Update"
    // />
  }
}

const mapStateToProps = ({ user, invites, orgs }) => ({ user, invites, orgs });
export default connect(mapStateToProps)(Profile);
