import { Route } from 'react-router-dom';

import MarkdownViewer from './modules/App/pages/MarkdownViewer';

const routes = [
  <Route key="boards-premium" path="/boards-premium" exact>
    <MarkdownViewer md="BoardsPremium" />
  </Route>,
];

export default routes;
