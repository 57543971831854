import config from '../../../../config';

/**
 * @param {string} boardsURL
 * @param {string} verseURL
 * @param {string} authPath
 * @returns {Promise<string>} The id of the created link
 */
export default async function createLink(boardsURL, verseURL, authPath) {
  const url = `${config.services.api}/bext/link`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      boardsURL,
      verseURL,
      authPath,
    }),
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });

  // eslint-disable-next-line no-return-await
  return await response.text();
}
