import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
} from 'react-md';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import { getOrgs, getPartnerOrgs } from '../OrgActions';

import './Orgs.scss';

class Orgs extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = { userIsPartner: false };
  constructor(props) {
    super(props);
    this.getOrgs = this.getOrgs.bind(this);
    this.openOrg = this.openOrg.bind(this);
  }
  getOrgs() {
    const { user } = this.props;
    const { info = {} } = this.props.user;

    this.props.dispatch(getOrgs(user.isAdmin)).then(() => {
      if (info.isPartner) {
        this.props.dispatch(getPartnerOrgs(info?.primaryOrg));
        //this.props.dispatch(getPartnerOrgs(info?.primaryOrg)).then(() => {this.props.dispatch(getOrg(info?.primaryOrg));});
        //         this.props.dispatch(getPartnerOrgs(info?.primaryOrg)).then(() => {this.props.dispatch(getOrgs(user.isAdmin));});
      }
    });
  }
  componentDidMount() {
    this._isMounted = true;
    //const { orgList, envUrl, contactName, contactPhone, contactAddress, contactWebsite } = this.state;
    this.getOrgs();
  }
  openOrg(org) {
    //this.props.dispatch(getOrg(id));
    this.setState({ selectedOrg: org });
  }
  render() {
    const { orgs } = this.props;
    // const { info = {} } = user;
    const { selectedOrg } = this.state;
    //if (!(user.isAdmin || info.isPartner)) return <Redirect to="/home" />;

    if (selectedOrg) {
      return <Redirect to={`/org/${selectedOrg.id}`} />;
    }

    // let partner = false;

    // for (const [index] of orgs.entries()) {
    //   if (orgs[index].isPartner) partner = true;
    // }

    let createButton = null;
    //if (orgs.length === 0 || partner) {
    createButton = (
      <Button primary raised href="/createorg" children="Create Organisation" />
    );
    //}

    return (
      <div className="org-list">
        <DataTable plain>
          <TableHeader>
            <TableRow>
              <TableColumn>Name</TableColumn>
              <TableColumn>Is Partner</TableColumn>
              <TableColumn>Contact</TableColumn>
              <TableColumn>URL</TableColumn>
              <TableColumn>Partner</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.keys(orgs).map((_, i) => (
              <TableRow
                className="org-row"
                key={i}
                onClick={() => this.openOrg(orgs[i])}
              >
                <TableColumn>{orgs[i].name}</TableColumn>
                <TableColumn>{orgs[i].isPartner ? 'Yes' : 'No'}</TableColumn>
                <TableColumn>{orgs[i].contact.name}</TableColumn>
                <TableColumn>{orgs[i].env.appURI}</TableColumn>
                <TableColumn>
                  {orgs[i].partner ? (
                    <Link to={'/org/' + orgs[i].partner}>Partner</Link>
                  ) : (
                    '-'
                  )}
                </TableColumn>
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
        {createButton}
      </div>
    );
  }
}

const mapStateToProps = ({ orgs, user }) => ({ orgs, user });
export default connect(mapStateToProps)(Orgs);
