import * as API from './OrgAPI';
import * as userAPI from './UserAPI';

export const ADD_ORGS = 'org/ADD_ORGS';
export const ADD_ORG = 'org/ADD_ORG';
export const GET_ORG = 'org/GET_ORG';
export const UPDATE_ORG = 'org/UPDATE_ORG';

function addOrgs(orgs) {
  return {
    type: ADD_ORGS,
    orgs,
  };
}

function addOrg(org) {
  return {
    type: ADD_ORG,
    org,
  };
}

function updateLocalOrg(id, delta) {
  return {
    type: UPDATE_ORG,
    id,
    delta,
  };
}

export function getOrgs(isAdmin) {
  return (dispatch) =>
    API.orgs(isAdmin).then((orgs) => {
      if (orgs) {
        dispatch(addOrgs(orgs));
        return orgs;
      }
    });
}

export function getOrg(id) {
  return (dispatch) =>
    API.org(id).then((org) => {
      if (org) {
        dispatch(addOrg(org));
      }
      return org;
    });
}

export function createOrg(
  name,
  employeeCount,
  isPartner,
  partner,
  contact,
  env,
  isPrimary
) {
  return (dispatch) =>
    API.create(name, employeeCount, isPartner, partner, contact, env).then(
      (org) => {
        if (isPrimary) {
          return userAPI
            .update(null, null, { primaryOrg: org.id })
            .then(() => org);
        }
        return org;
      }
    );
}

export function updateOrg(id, name, partner, contact, env) {
  return (dispatch) => {
    API.update(id, name, partner, contact, env).then(() => {
      dispatch(updateLocalOrg(id, { name, partner, contact, env }));
    });
  };
}

export function togglePartner(id, isPartner) {
  return (dispatch) => {
    API.togglePartner(id, isPartner).then(() => {
      dispatch(updateLocalOrg(id, { isPartner }));
    });
  };
}

export function getPartnerOrgs(partnerId) {
  return (dispatch) =>
    API.parterOrgs(partnerId).then((orgs) => {
      if (orgs) {
        orgs.forEach((org) => {
          dispatch(addOrg(org));
        });
        //dispatch(addOrgs(orgs));
        return orgs;
      }
    });
}

export function getBasicDetails(orgId) {
  return (dispatch) => {
    API.basicDetails(orgId).then((org) => {
      if (org) {
        dispatch(addOrg(org));
      }
      return org;
    });
  };
}
