import PropTypes from 'prop-types';
import { Component } from 'react';
import { TextField, Button, Subheader } from 'react-md';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { register } from '../UserActions';

class Register extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {
    name: '',
    email: '',
    password: '',
    secondPassword: '',
    nameError: false,
    nameErrorMsg: '',
    emailError: false,
    emailErrorMsg: '',
    passwordError: false,
    passwordErrorMsg: '',
    secondPasswordError: false,
    secondPasswordErrorMsg: '',
    submitError: false,
    submitSuccess: false,
  };
  constructor(props) {
    super(props);
    this.register = this.register.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateSecondPassword = this.validateSecondPassword.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }
  register(e) {
    e.preventDefault();
    const valid = this.validateForm();
    if (valid) {
      const { name, email, password } = this.state;

      this.props
        .dispatch(
          register(name, email, password, { primaryOrg: '', isPartner: false })
        )
        .then(() => {
          this.setState({ submitSuccess: true });
        })
        .catch((error) => {
          this.setState({ submitError: true });
        });
    }
    return false;
  }
  validateName() {
    if (!this.state.name) {
      this.setState({ nameError: true, nameErrorMsg: 'Your name is required' });
      return false;
    }
    this.setState({ nameError: false, nameErrorMsg: '' });
    return true;
  }
  validateEmail() {
    if (!this.state.email) {
      this.setState({
        emailError: true,
        emailErrorMsg: 'Your email is required',
      });
      return false;
    }
    //let lowerEmail = this.state.email.toLowerCase();
    this.setState({ emailError: false, emailErrorMsg: '' });
    return true;
  }
  validatePassword() {
    if (!this.state.password) {
      this.setState({
        passwordError: true,
        passwordErrorMsg: 'Password is required',
      });
      return false;
    }
    this.setState({ passwordError: false, passwordErrorMsg: '' });
    return true;
  }
  validateSecondPassword() {
    if (!this.state.secondPassword) {
      this.setState({
        secondPasswordError: true,
        secondPasswordErrorMsg: 'Password confirmation is required',
      });
      return false;
    } else if (this.state.password && this.state.secondPassword) {
      if (this.state.password !== this.state.secondPassword) {
        this.setState({
          secondPasswordError: true,
          secondPasswordErrorMsg: 'Passwords must match',
        });
        return false;
      }
      this.setState({ secondPasswordError: false, secondPasswordErrorMsg: '' });
    }
    return true;
  }
  validateForm() {
    const nameCorrect = this.validateName();
    const nameEmail = this.validateEmail();
    const namePassword = this.validatePassword();
    const nameSecondPassword = this.validateSecondPassword();

    return nameCorrect && nameEmail && namePassword && nameSecondPassword;
  }
  render() {
    const {
      name,
      email,
      password,
      secondPassword,
      submitError,
      submitSuccess,
    } = this.state;

    if (submitSuccess) {
      return (
        <div>
          Registration successful. Verification email sent. Please check your
          email
        </div>
      );
    }

    return (
      <div>
        <h1>Welcome to the Huddo Store</h1>
        Huddo Boards Premium View lets you enhance your Activities Plus work
        space. View your Activities as Boards, MindMaps, Timelines and drive
        collaborative team work. <br />
        Enable Huddo Boards Premium View for your organisation today.{' '}
        <Link to="/login">Login</Link> or Register to get started.
        <br />
        <br />
        <Subheader primary component="div" primaryText="Register" />
        <form onSubmit={this.register}>
          <TextField
            id="ks-name"
            label="Name"
            lineDirection="center"
            error={this.state.nameError}
            errorText={this.state.nameErrorMsg}
            value={name}
            onChange={(name) => this.setState({ name })}
            onBlur={this.validateName}
          />
          <TextField
            id="ks-email"
            label="Email"
            lineDirection="center"
            type="email"
            error={this.state.emailError}
            errorText={this.state.emailErrorMsg}
            value={email}
            onChange={(email) => this.setState({ email })}
            onBlur={this.validateEmail}
          />
          <TextField
            id="ks-password"
            label="Enter your password"
            type="password"
            error={this.state.passwordError}
            errorText={this.state.passwordErrorMsg}
            value={password}
            onChange={(password) => this.setState({ password })}
            onBlur={this.validatePassword}
          />
          <TextField
            id="ks-secondpassword"
            label="Reenter your password"
            type="password"
            error={this.state.secondPasswordError}
            errorText={this.state.secondPasswordErrorMsg}
            value={secondPassword}
            onChange={(secondPassword) => this.setState({ secondPassword })}
            onBlur={this.validateSecondPassword}
          />
          <div
            id="errorMsg"
            style={{ color: 'red' }}
            className={submitError ? 'ks-show' : 'ks-hide'}
          >
            Registration failed. Email may already exist.
          </div>
          <Button primary raised type="submit" children="Register" />
        </form>
        <Link to="/login">Login</Link>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(Register);
