import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { SelectField } from 'react-md';

const EmployeeCount = ({ value, onChange, onErrorChange, forceValidate }) => {
  const [error, setError] = useState(false);
  const [enableValidate, setEnableValidate] = useState(false);

  const validate = useCallback(() => {
    const isInvalid = typeof value !== 'number';
    if (isInvalid !== error) {
      setError(!error);
      onErrorChange(!error);
    }
  }, [value, error, onErrorChange]);

  useEffect(() => {
    if (enableValidate || forceValidate) validate();
  }, [validate, enableValidate, forceValidate]);

  return (
    <SelectField
      id="employeeCount"
      label="Number of Employees"
      fullWidth
      required
      menuItems={[25, 50, 100, 250, 1000, 5000, 10000, 25000, 100000]}
      value={value}
      onChange={(value) => {
        setEnableValidate(true);
        onChange(value);
      }}
      error={error}
      onBlur={() => {
        setEnableValidate(true);
      }}
    />
  );
};

EmployeeCount.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onErrorChange: PropTypes.func.isRequired,
  forceValidate: PropTypes.bool,
};

export default EmployeeCount;
