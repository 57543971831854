import PropTypes from 'prop-types';
import { Component } from 'react';
//import { Button } from 'react-md';
import { connect } from 'react-redux';

//import { Redirect } from 'react-router-dom';
import MarkdownViewer from '../../App/pages/MarkdownViewer';

//import { logout } from '../UserActions';

class Home extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const { user } = this.props;
    if (user.isAdmin) {
      return (
        <div>
          Welcome {user.name} to the Huddo Store.
          <MarkdownViewer md="store" />
        </div>
      );
    }
    return (
      <div>
        Welcome {user.name} to the Huddo Store.
        <MarkdownViewer md="store" />
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(Home);
