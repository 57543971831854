/* eslint-disable no-invalid-this */
import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  TextField,
  Button,
  FontIcon,
  DialogContainer,
  SelectField,
  Subheader,
  List,
  ListItem,
  Tooltipped,
} from 'react-md';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { createOrg, getOrgs } from '../../OrgActions';
import { clientProvider, clientProviderView } from '../providers';
import EmployeeCount from './EmployeeCount';

import './CreateOrg.scss';

class Org extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {
    addDialogVisible: false,
    name: '',
    envUrl: '',
    contactName: '',
    contactPhone: '',
    contactAddress: '',
    contactWebsite: '',
    partner: '',
    envAutoLink: '',
    envClientProvider: '',
    envClientExternalId: '',
    envClientURL: '',
    clients: [],
    nameError: false,
    nameErrorMsg: '',
    envUrlError: false,
    envUrlErrorMsg: '',
    envEmployeeCountValidate: false,
    envEmployeeCountError: false,
    envEmployeeCount: undefined,
    contactNameError: false,
    contactNameErrorMsg: '',
    contactPhoneError: false,
    contactPhoneErrorMsg: '',
    contactAddressError: false,
    contactAddressErrorMsg: '',
    contactWebsiteError: false,
    contactWebsiteErrorMsg: '',
    envClientError: false,
    envClientErrorMsg: '',
    envClientProviderError: false,
    envClientExternalIdError: false,
    envClientURLError: false,
    envClientProviderErrorMsg: '',
    envClientExternalIdErrorMsg: '',
    envClientURLErrorMsg: '',
    primaryOrgExists: false,
  };

  constructor(props) {
    super(props);
    this.getOrgs = this.getOrgs.bind(this);
    this.createOrg = this.createOrg.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateURL = this.validateURL.bind(this);
    this.validateContactName = this.validateContactName.bind(this);
    this.validateContactPhone = this.validateContactPhone.bind(this);
    this.validateContactEmail = this.validateContactEmail.bind(this);
    this.validateContactWebsite = this.validateContactWebsite.bind(this);
    this.validateClient = this.validateClient.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.changeProvider = this.changeProvider.bind(this);
  }
  getOrgs() {
    const { user } = this.props;
    this.props.dispatch(getOrgs(user.isAdmin));
  }
  createOrg(e) {
    const { info = {} } = this.props.user;
    const valid = this.validateForm();

    const isPrimary = !info?.primaryOrg;

    if (!valid) {
      e.preventDefault();
    } else {
      const contact = {
        name: this.state.contactName,
        phone: this.state.contactPhone,
        address: this.state.contactAddress,
        website: this.state.contactWebsite,
      };
      const env = {
        appURI: this.state.envUrl,
        clients: this.state.clients,
      };

      let partner = null;
      if (info.isPartner) partner = info?.primaryOrg;

      this.props
        .dispatch(
          createOrg(
            this.state.name,
            this.state.envEmployeeCount,
            false,
            partner,
            contact,
            env,
            isPrimary
          )
        )
        .then((org) => {
          this.setState({ redirect: '/org/' + org._id });
        });
    }
  }

  validateName() {
    if (!this.state.name) {
      this.setState({
        nameError: true,
        nameErrorMsg: 'Organisation name is required',
      });
      return false;
    }
    this.setState({ nameError: false, nameErrorMsg: '' });
    return true;
  }
  validateURL() {
    if (!this.state.envUrl) {
      this.setState({
        envUrlError: true,
        // envUrlErrorMsg: 'Your Boards URL is required',
      });
      return false;
    }
    this.setState({ envUrlError: false, envUrlErrorMsg: '' });
    return true;
  }
  validateEmployeeCount() {
    console.log('validateEmployeeCount | validateEmployeeCount');
    this.setState({ envEmployeeCountValidate: true });
    return typeof this.state.envEmployeeCount === 'number';
  }
  validateContactName() {
    if (!this.state.contactName) {
      this.setState({
        contactNameError: true,
        contactNameErrorMsg: 'Contact name is required',
      });
      return false;
    }
    this.setState({ contactNameError: false, contactNameErrorMsg: '' });
    return true;
  }
  validateContactPhone() {
    // if(!this.state.contactPhone)
    // {
    //   this.setState({contactPhoneError:true, contactPhoneErrorMsg:"Contact phone is required"});
    //   return false;
    // }
    this.setState({ contactPhoneError: false, contactPhoneErrorMsg: '' });
    return true;
  }
  validateContactEmail() {
    if (!this.state.contactAddress) {
      this.setState({
        contactAddressError: true,
        contactAddressErrorMsg: 'Contact Email is required',
      });
      return false;
    }
    this.setState({ contactAddressError: false, contactAddressErrorMsg: '' });
    return true;
  }
  validateContactWebsite() {
    // if(!this.state.contactWebsite)
    // {
    //   this.setState({contactWebsiteError:true, contactWebsiteErrorMsg:"Contact Website is required"});
    //   return false;
    // }
    this.setState({ contactWebsiteError: false, contactWebsiteErrorMsg: '' });
    return true;
  }
  validateClient() {
    if (this.state.clients.length === 0) {
      this.setState({
        envClientError: true,
        envClientErrorMsg: 'Client details are required',
      });
      return false;
    }
    this.setState({ envClientError: false, envClientErrorMsg: '' });
    return true;
  }
  validateForm() {
    const nameCorrect = this.validateName();
    const urlCorrect = this.validateURL();
    const employeeCountCorrect = this.validateEmployeeCount();
    const contactNameCorrect = this.validateContactName();
    const contactPhoneCorrect = this.validateContactPhone();
    const contactEmailCorrect = this.validateContactEmail();
    const contactWebsiteCorrect = this.validateContactWebsite();
    const clientCorrect = this.validateClient();

    return (
      nameCorrect &&
      urlCorrect &&
      employeeCountCorrect &&
      contactNameCorrect &&
      contactPhoneCorrect &&
      contactEmailCorrect &&
      contactWebsiteCorrect &&
      clientCorrect
    );
  }

  changeProvider() {
    const { envClientURL, envClientProvider } = this.state;
    if (['connections', 'domino', 'dx', 'msad'].includes(envClientProvider)) {
      const encodedString = btoa(envClientURL);
      this.setState({
        envClientExternalId: encodedString,
      });
    } else {
      this.setState({
        envClientURL: '',
        envClientExternalId: '',
      });
    }
  }

  showAddDialog = () => {
    //const { envUrl } = this.state;
    //const encodedString = btoa(envUrl);
    this.setState({
      envClientProvider: 'connections',
      envClientURL: '',
      envClientExternalId: '',
    });
    this.setState({ addDialogVisible: true });
  };

  hideAddDialog = () => {
    this.setState({ addDialogVisible: false });

    this.setState({
      envClientExternalIdError: false,
      envClientExternalIdErrorMsg: '',
      envClientProviderError: false,
      envClientProviderErrorMsg: '',
      envClientURLError: false,
      envClientURLErrorMsg: '',
    });
  };

  addClient = () => {
    const { clients, envClientProvider, envClientURL, envClientExternalId } =
      this.state;
    let valid = true;

    if (!envClientProvider) {
      this.setState({
        envClientProviderError: true,
        envClientProviderErrorMsg: 'This is required',
      });
      valid = false;
    } else {
      this.setState({
        envClientProviderError: false,
        envClientProviderErrorMsg: '',
      });
    }

    if (['connections', 'domino', 'dx', 'msad'].includes(envClientProvider)) {
      if (!envClientURL) {
        this.setState({
          envClientURLError: true,
          envClientURLErrorMsg: 'This is required',
        });
        valid = false;
      } else {
        this.setState({
          envClientURLError: false,
          envClientURLErrorMsg: '',
          envClientURL,
        });
      }
    }

    if (envClientProvider === 'msgraph') {
      if (!envClientExternalId) {
        this.setState({
          envClientExternalIdError: true,
          envClientExternalIdErrorMsg: 'This is required',
        });
        valid = false;
      } else {
        this.setState({
          envClientExternalIdError: false,
          envClientExternalIdErrorMsg: '',
        });
      }
    }
    if (valid) {
      const encodedString = btoa(envClientURL);
      const externalId =
        envClientProvider === 'msgraph' ? envClientExternalId : encodedString;

      clients.push({
        provider: envClientProvider,
        url: envClientURL,
        externalId,
      });
      this.setState({ clients });
      this.hideAddDialog();
    }
  };
  componentDidMount() {
    const { info = {} } = this.props.user;
    this.getOrgs();

    if (info?.primaryOrg) this.setState({ primaryOrgExists: true });
  }
  render() {
    // const { user } = this.props;
    // const { info = {} } = this.props.user;
    const {
      name,
      envUrl,
      contactName,
      contactPhone,
      contactAddress,
      contactWebsite,
      envClientProvider,
      envClientURL,
      envClientExternalId,
      redirect,
      // primaryOrgExists,
    } = this.state;

    //if (!(user.isAdmin || info.isPartner) && primaryOrgExists)
    //  return <Redirect to={'/org/' + info?.primaryOrg} />;

    if (redirect) return <Redirect to={redirect} />;

    const actions = [];
    actions.push({
      secondary: true,
      children: 'Cancel',
      onClick: this.hideAddDialog,
    });
    actions.push(
      <Button flat primary onClick={this.addClient}>
        Add
      </Button>
    );

    let clientListItems;

    if (this.state.clients) {
      clientListItems = Object.keys(this.state.clients).map((_, i) => (
        <ListItem
          key={i}
          primaryText={clientProviderView[this.state.clients[i].provider]}
          secondaryText={
            this.state.clients[i].provider === 'msgraph'
              ? this.state.clients[i].externalId
              : this.state.clients[i].url
          }
        />
      ));
    }

    return (
      <div className="create-org">
        <Subheader primary component="div" primaryText="Organisation Details" />
        <TextField
          id="floating-center-title"
          label="Name"
          lineDirection="center"
          error={this.state.nameError}
          errorText={this.state.nameErrorMsg}
          required
          fullWidth
          value={name}
          onChange={(name) => this.setState({ name })}
          onBlur={this.validateName}
        />
        <EmployeeCount
          value={this.state.envEmployeeCount}
          onChange={(value) => this.setState({ envEmployeeCount: value })}
          onErrorChange={(value) =>
            this.setState({ envEmployeeCountError: value })
          }
          forceValidate={this.state.envEmployeeCountValidate}
        />
        <TextField
          id="floating-center-title"
          label="Boards Production Hostname and Path"
          helpText="e.g. connections.company.com/boards"
          lineDirection="center"
          error={this.state.envUrlError}
          errorText={this.state.envUrlErrorMsg}
          required
          fullWidth
          value={envUrl}
          onChange={(value) => {
            const envUrl = value
              .replace(/^http(s)?:\/\//, '')
              .replace(/[^a-zA-Z0-9-./]/g, '');
            this.setState({ envUrl });
          }}
          onBlur={this.validateURL}
        />
        <Subheader primary component="div" primaryText="Main Contact" />
        <TextField
          id="floating-center-title"
          label="Name"
          lineDirection="center"
          error={this.state.contactNameError}
          errorText={this.state.contactNameErrorMsg}
          required={true}
          fullWidth
          value={contactName}
          onChange={(contactName) => this.setState({ contactName })}
          onBlur={this.validateContactName}
        />
        <TextField
          id="floating-center-title"
          label="Phone Number"
          lineDirection="center"
          error={this.state.contactPhoneError}
          errorText={this.state.contactPhoneErrorMsg}
          value={contactPhone}
          fullWidth
          onChange={(contactPhone) => this.setState({ contactPhone })}
          onBlur={this.validateContactPhone}
        />
        <TextField
          id="floating-center-title"
          label="Email"
          lineDirection="center"
          error={this.state.contactAddressError}
          errorText={this.state.contactAddressErrorMsg}
          type="email"
          required={true}
          fullWidth
          value={contactAddress}
          onChange={(contactAddress) => this.setState({ contactAddress })}
          onBlur={this.validateContactEmail}
        />
        <TextField
          id="floating-center-title"
          label="Website"
          lineDirection="center"
          error={this.state.contactWebsiteError}
          errorText={this.state.contactWebsiteErrorMsg}
          value={contactWebsite}
          fullWidth
          onChange={(contactWebsite) => this.setState({ contactWebsite })}
          onBlur={this.validateContactWebsite}
        />
        <Subheader primary component="div" primaryText="Login Clients" />
        <Tooltipped
          label="Used for authentication. You may have multiple of these "
          position="right"
        >
          <Button
            flat
            onClick={this.showAddDialog}
            iconBefore
            iconEl={<FontIcon>add</FontIcon>}
          >
            Add Client
          </Button>
        </Tooltipped>
        <span
          className={
            this.state.envClientError
              ? 'ks-orgClientError'
              : 'ks-orgClientErrorHide'
          }
        >
          At least one client is required
        </span>
        <DialogContainer
          width={600}
          contentStyle={{ height: 400 }}
          id="simple-action-dialog"
          visible={this.state.addDialogVisible}
          onHide={this.hideAddDialog}
          actions={actions}
          title="Add Client"
        >
          These are login clients used for authentication. One per environment.
          <SelectField
            id="select-field-2"
            label="Provider"
            error={this.state.envClientProviderError}
            errorText={this.state.envClientProviderErrorMsg}
            menuItems={clientProvider}
            value={envClientProvider}
            fullWidth
            onChange={(envClientProvider) =>
              this.setState({ envClientProvider })
            }
            onVisibilityChange={this.changeProvider}
          />
          <TextField
            id="simple-action-dialog-field"
            label={'Hostname'}
            className={envClientProvider === 'msgraph' ? 'ks-hide' : 'ks-show'}
            error={this.state.envClientURLError}
            errorText={this.state.envClientURLErrorMsg}
            placeholder={envClientProvider + '.company.com'}
            value={envClientURL}
            fullWidth
            onChange={(value) => {
              this.setState({
                envClientURL: value
                  .replace(/^http(s)?:\/\//, '') // remove protocol when pasting
                  .split('/')[0] // remove path when pasting
                  .replace(/[^a-zA-Z0-9.-]/g, ''), // remove invalid characters when typing
              });
            }}
          />
          <TextField
            id="simple-action-dialog-field"
            label="Tenant ID"
            className={envClientProvider === 'msgraph' ? 'ks-show' : 'ks-hide'}
            error={this.state.envClientExternalIdError}
            errorText={this.state.envClientExternalIdErrorMsg}
            value={envClientExternalId}
            fullWidth
            onChange={(envClientExternalId) =>
              this.setState({ envClientExternalId })
            }
          />
        </DialogContainer>
        <List>{clientListItems}</List>
        <Button
          primary
          raised
          onClick={this.createOrg}
          className="create-org-button"
          children="Create Org"
        />
      </div>
    );
  }
}

const mapStateToProps = ({ user, orgs }) => ({ user, orgs });
export default connect(mapStateToProps)(Org);
