import { ADD_USER, REMOVE_USER } from './UserActions';

const defaultState = {};

export default function userReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_USER: {
      return {
        ...state,
        ...action.user,
      };
    }
    case REMOVE_USER: {
      return {};
    }
    default: {
      return state;
    }
  }
}
