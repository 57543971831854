import { Route, Switch } from 'react-router-dom';

import GenericRoutes from './GenericRoutes';
import BoardsExtensions from './modules/User/pages/BoardsExtensions';
import CreateLicence from './modules/User/pages/CreateLicence';
import CreateOrg from './modules/User/pages/CreateOrg';
import Home from './modules/User/pages/Home';
import InviteAccept from './modules/User/pages/InviteAccept';
import Licences from './modules/User/pages/Licences';
import Logout from './modules/User/pages/Logout';
import Org from './modules/User/pages/Org';
import Orgs from './modules/User/pages/Orgs';
import Profile from './modules/User/pages/Profile';
import Users from './modules/User/pages/Users';

function AppRouter() {
  return (
    <Switch>
      <Route path="/home" exact component={Home} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/orgs" exact component={Orgs} />
      <Route path="/org/:orgId" exact component={Org} />
      <Route
        path="/org/:orgId/admin/:adminId/accept"
        exact
        component={InviteAccept}
      />
      <Route path="/org/" exact component={Org} />
      <Route path="/createorg" exact component={CreateOrg} />
      <Route path="/licences" exact component={Licences} />
      <Route path="/boards-extensions" component={BoardsExtensions} />
      <Route path="/users" exact component={Users} />
      <Route path="/createlicence/:orgId" exact component={CreateLicence} />
      <Route path="/createlicence" exact component={CreateLicence} />
      <Route path="/logout" exact component={Logout} />
      {GenericRoutes}
      <Route component={Home} />
    </Switch>
  );
}

///org/5e6056ef4a160f70a25e9a8b/admin/5e6ef07732cba795d0bf6d72/accept

export default AppRouter;
