import PropTypes from 'prop-types';
import { Component } from 'react';
import { Drawer, CircularProgress, Button } from 'react-md';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { getOrg } from '../User/OrgActions';
import { getCurrentUser, updateUser, logout } from '../User/UserActions';
import NavItemLink from './NavItemLink';
const TO_PREFIX = '';

class App extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };
  state = {
    ready: false,
    drawerOpen: true,
    mobile: false,
  };

  constructor(props) {
    super(props);
    this.getUser = this.getUser.bind(this);
    this.logout = this.logout.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.getOrg = this.getOrg.bind(this);
  }
  getNavItems() {
    const { verified, info = {}, isAdmin } = this.props.user;

    const navItems = [
      {
        label: 'Home',
        to: `${TO_PREFIX}/home`,
        exact: true,
        icon: 'home',
        primaryText: 'Home',
        render: verified,
      },
      {
        label: 'Profile',
        to: `${TO_PREFIX}/profile`,
        icon: 'person',
        primaryText: 'Profile',
        render: verified,
      },
      {
        label: 'My Organisation',
        to: `${TO_PREFIX}/org/${info?.primaryOrg || ''}`,
        icon: 'business_center',
        primaryText: 'My Organisation',
        render: verified,
      },
      {
        label: 'Organisations',
        to: `${TO_PREFIX}/orgs`,
        icon: 'business_center',
        primaryText: 'Organisations',
        render: verified,
      },
      {
        label: 'Licences',
        to: `${TO_PREFIX}/licences`,
        icon: 'get_app',
        primaryText: 'Licences',
        render: verified && isAdmin,
      },
      {
        label: 'Users',
        to: `${TO_PREFIX}/users`,
        icon: 'group',
        primaryText: 'Users',
        render: verified && isAdmin,
      },
      {
        divider: true,
        label: 'Divider',
        render: true,
      },
      {
        label: 'Boards Extensions',
        to: `${TO_PREFIX}/boards-extensions`,
        icon: 'extension',
        primaryText: 'Boards Extensions',
        render: verified,
      },
      {
        divider: true,
        label: 'Divider',
        render: true,
      },
      {
        label: 'Logout',
        onClick: () => this.logout(),
        icon: 'logout',
        primaryText: 'Logout',
        render: true,
        to: '',
      },
    ];

    return navItems;
  }
  getUser() {
    this.props
      .dispatch(getCurrentUser())
      .catch(() => 1) // set ready state even if user failed
      .then(() => {
        this.getOrg();
        this.setState({ ready: true });
      });
  }
  getOrg() {
    const { info = {} } = this.props.user;
    if (info?.primaryOrg) {
      this.props.dispatch(getOrg(info?.primaryOrg)).then((org) => {
        const newInfo = info;
        newInfo.isPartner = org.isPartner;
        this.props.dispatch(updateUser(null, null, newInfo));
      });
    } else {
      const newInfo = info;
      newInfo.isPartner = false;
      this.props.dispatch(updateUser(null, null, info));
    }
  }
  logout() {
    this.props.dispatch(logout());
  }
  componentDidMount() {
    this.getUser();
    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize();
  }
  onWindowResize() {
    const { clientWidth } = document.body;
    const mobile = clientWidth < 1025;
    this.setState({ mobile, drawerOpen: !mobile });
  }

  getCurrentTitle = ({ location: { pathname } }) => {
    const lastSection = pathname.substring(pathname.lastIndexOf('/') + 1);
    if (lastSection === 'navigation-drawers') {
      return 'Inbox';
    }

    return lastSection;
  };

  render() {
    const { ready, drawerOpen, mobile } = this.state;
    const { children, user } = this.props;

    let drawer;

    if (user.email) {
      drawer = (
        <Drawer
          zDepth={0}
          location={this.props.location}
          type={mobile ? Drawer.DrawerTypes.TEMPORARY : undefined}
          visible={drawerOpen}
          onVisibilityChange={(drawerOpen) => this.setState({ drawerOpen })}
          overlay={mobile}
          autoclose={mobile}
          //contentId="main-demo-content"
          className="huddo-lp-sidebar"
          navItems={this.getNavItems().map((props) => (
            <NavItemLink {...props} key={props.label} />
          ))}
        />
      );
    } else {
      drawer = null;
    }
    return (
      <div className="app">
        <div className="huddo-main-logo" />
        <div className="app-header">
          {mobile && (
            <Button
              icon
              onClick={() =>
                this.setState(({ drawerOpen }) => ({ drawerOpen: !drawerOpen }))
              }
            >
              menu
            </Button>
          )}
          <Link to="/">Huddo Store</Link>
        </div>
        {drawer}
        <div className="app-body">
          {ready ? children : <CircularProgress id="ks-progress" />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user, org }) => ({ user, org });
export default withRouter(connect(mapStateToProps)(App));
//export default App;
