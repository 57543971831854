import { useCallback } from 'react';

/**
 * @param {(value:string) => void} setter
 *
 * A change handler for URL inputs that prevents invalid input
 */
export function useURLChangeHandler(setter) {
  return useCallback(
    (/** @type {string} */ value) => {
      console.log('setValue | value:', typeof value);
      if (!value) {
        setter('https://');
      } else if (!/https:\/\/[a-zA-Z0-9-./]*$/.test(value)) {
        // block changes that aren't valid
        console.warn('Attempted invalid input to VerseURLInput', value);
        return;
      } else if (value.startsWith('https://https://')) {
        setter(value.slice(0, 8));
      } else if (!value.startsWith('https://')) {
        setter('https://' + value);
      } else {
        setter(value);
      }
    },
    [setter]
  );
}
