import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import LoggedInRoutes from './LoggedInRoutes';
import LoggedOutRoutes from './LoggedOutRoutes';
import AppModule from './modules/App/App';
import { getCurrentUser } from './modules/User/UserActions';
import UnverifiedRoutes from './UnverifiedRoutes';

class App extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {};

  constructor(props) {
    super(props);
    this.getUser = this.getUser.bind(this);
  }
  getUser() {
    this.props.dispatch(getCurrentUser());
  }
  componentDidMount() {
    this.getConfig();
  }
  getConfig() {
    fetch('/config.json')
      .then((r) => r.json())
      .then((config) => {
        window.config = config;
        this.setState({ configReady: true });
        this.getUser();
      });
  }
  render() {
    const { configReady } = this.state;
    if (!configReady) return null;

    const { user } = this.props;
    return (
      <BrowserRouter>
        <AppModule>
          {user.email ? (
            user.verified ? (
              <LoggedInRoutes />
            ) : (
              <UnverifiedRoutes />
            )
          ) : (
            <LoggedOutRoutes />
          )}
        </AppModule>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(App);
//export default App;
