import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

export default function MarkdownViewer({ md }) {
  const [source, setSource] = useState('');
  useEffect(() => {
    fetch(`/md/${md}.md`)
      .then((r) => r.text())
      .then(setSource);
  }, [md]);

  return (
    <ReactMarkdown
      children={source}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
    />
  );
}
