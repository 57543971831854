import { ADD_USERS } from './UserActions';

const defaultState = {};

export default function usersReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_USERS: {
      return {
        ...state,
        ...action.users,
      };
    }
    default: {
      return state;
    }
  }
}
