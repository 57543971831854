import { Route, Switch } from 'react-router-dom';

import GenericRoutes from './GenericRoutes';
import ForgotPassword from './modules/User/pages/ForgotPassword';
import Login from './modules/User/pages/Login';
import Register from './modules/User/pages/Register';
import Verify from './modules/User/pages/Verify';

function AppRouter() {
  return (
    <Switch>
      <Route path="/register" exact component={Register} />
      <Route path="/login" exact component={Login} />
      <Route path="/user/verify" exact component={Verify} />
      <Route path="/user/reset" exact component={ForgotPassword} />
      {GenericRoutes}
      <Route component={Login} />
    </Switch>
  );
}

export default AppRouter;
