import callApi from '../../util/apiCaller';

export function create(name, employeeCount, isPartner, partner, contact, env) {
  return callApi('org', 'post', {
    name,
    employeeCount,
    isPartner,
    partner,
    contact,
    env,
  });
}

export function update(id, name, partner, contact, env) {
  return callApi('org/' + id, 'put', {
    name,
    partner,
    contact,
    env,
  });
}

export function togglePartner(id, isPartner) {
  return callApi('org/' + id + '/togglePartner', 'put', { isPartner });
}

export function orgs(isAdmin) {
  if (isAdmin) return callApi('org');
  return callApi('org/my');
}

/**
 * @typedef {{ externalId: string, provider: string, url: string, _id: string }} OrgEnvClient
 * @typedef {{ appURI:string, clients:OrgEnvClient[], autoLink: boolean }} OrgEnv
 * @typedef {{ env: OrgEnv, name:string }} Org
 */

/**
 *
 * @param {string} id
 * @returns {Promise<Org>}
 */
export function org(id) {
  // @ts-ignore
  return callApi('org/' + id);
}

export function parterOrgs(partnerId) {
  return callApi('org/partner/' + partnerId);
}

export function basicDetails(orgId) {
  return callApi('org/' + orgId + '/basic');
}
