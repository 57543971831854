import { useCallback } from 'react';
import { TextField } from 'react-md';

import placeholders from './placeholders';
import { useURLChangeHandler } from './useURLChangeHandler';

/**
 * @param {{className?:string, value?:string, onChange: (value:string) => void}} props
 */
const VerseURLInput = ({ className = '', value, onChange }) => {
  const setValue = useURLChangeHandler(onChange);

  return (
    <TextField
      label="Verse URL"
      id={'verse-url'}
      className={`${className}`}
      helpText={`e.g. ${placeholders.verseURL}`}
      value={value}
      onChange={useCallback(
        (/** @type {string|number} */ value) => {
          setValue(value ? `${value}` : '');
        },
        [setValue]
      )}
    />
  );
};

export default VerseURLInput;
