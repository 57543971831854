import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrgs } from './OrgActions';

/**
 * get orgs from redux, or load them if they aren't already there
 * @returns {import('./OrgAPI').Org[] | undefined}
 */
export function useOrgs() {
  const dispatch = useDispatch();
  const orgs = useSelector((state) => state.orgs);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    // load orgs in to redux if they aren't already
    if (!orgs.length) dispatch(getOrgs(user.isAdmin));
  }, [dispatch, orgs.length, user.isAdmin]);

  return orgs;
}
