import { ADD_LICENCES, ADD_LICENCE } from './LicenceActions';

const defaultState = [];

export default function licencesReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_LICENCES: {
      return action.licences;
    }
    case ADD_LICENCE: {
      return [
        action.licence,
        ...state.filter((l) => l._id !== action.licence._id),
      ];
    }
    default: {
      return state;
    }
  }
}
