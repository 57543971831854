import { ADD_INVITES, REMOVE_INVITE } from './UserActions';

const defaultState = [];

export default function inviteReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_INVITES: {
      return action.invites;
    }
    case REMOVE_INVITE: {
      return [...state.filter((invite) => invite._id !== action.invite._id)];
    }
    default: {
      return state;
    }
  }
}
