/* eslint-disable no-invalid-this */
import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  TextField,
  Button,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Subheader,
  List,
  ListItem,
  DialogContainer,
  SelectField,
  FontIcon,
} from 'react-md';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import config from '../../../config';
import { getLicences, createLicence, getTrialLicence } from '../LicenceActions';
import { getOrg, getOrgs, togglePartner, updateOrg } from '../OrgActions';
import { getOrgAdmins, inviteOrgAdmin } from '../OrgAdminActions';
import { updateUser } from '../UserActions';
import DownloadLicenceDialog from './DownloadLicenceDialog';
import {
  clientProvider,
  clientProviderView,
  isActivitiesPlus,
} from './providers';

import './Org.scss';

// const productNames = {
//   'com.kudosapps.boards.lite': 'ACTIVITIES+',
//   'com.kudosapps.boards.premium': 'BOARDS PREMIUM',
// };

// const productType = {
//   named: 'Named',
//   number: 'Number',
// };

class Org extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {
    addDialogVisible: false,
    name: '',
    contactName: '',
    contactPhone: '',
    contactAddress: '',
    contactWebsite: '',
    envUrl: '',
    envAutoLink: '',
    envClientProvider: '',
    envClientExternalId: '',
    envClientURL: '',
    clients: [],
    envClientError: false,
    envClientErrorMsg: '',
    envClientProviderError: false,
    envClientExternalIdError: false,
    envClientURLError: false,
    envClientProviderErrorMsg: '',
    envClientExternalIdErrorMsg: '',
    envClientURLErrorMsg: '',
    primaryOrgExists: false,
    inviteEmail: '',
    inviteEmailError: false,
    inviteEmailErrorMsg: '',
    currentPrimaryOrg: false,
  };
  constructor(props) {
    super(props);
    this.getOrg = this.getOrg.bind(this);
    this.getOrgAdmins = this.getOrgAdmins.bind(this);
    this.inviteOrgAdmin = this.inviteOrgAdmin.bind(this);
    this.updateOrg = this.updateOrg.bind(this);
    this.getLicences = this.getLicences.bind(this);
    this.createTrialLicence = this.createTrialLicence.bind(this);
    this.createActivitiesLicence = this.createActivitiesLicence.bind(this);
    this.togglePartner = this.togglePartner.bind(this);
    this.makePrimary = this.makePrimary.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateContactName = this.validateContactName.bind(this);
    this.validateContactPhone = this.validateContactPhone.bind(this);
    this.validateContactEmail = this.validateContactEmail.bind(this);
    this.validateContactWebsite = this.validateContactWebsite.bind(this);
    this.validateClient = this.validateClient.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.fixUrl = this.fixUrl.bind(this);
  }
  componentDidMount() {
    const { info = {} } = this.props.user;
    const { org } = this.props;
    this.getOrg();
    this.getLicences();
    this.getOrgAdmins();
    if (this.props.user.isAdmin) {
      this.getOrgAdmins();
    }

    if (info?.primaryOrg) {
      this.setState({ primaryOrgExists: true });
      if (org)
        this.setState({ currentPrimaryOrg: org._id === info?.primaryOrg });
    }

    //this.state.clients
    //this.setState({clients:org.env.clients});
  }

  // eslint-disable-next-line class-methods-use-this
  checkActivitiesLicenceExpiry(date) {
    const aMonthForward = new Date();
    const licenceDate = new Date(date);
    aMonthForward.setMonth(aMonthForward.getMonth() + 1);
    aMonthForward.setHours(23);
    aMonthForward.setMinutes(59);
    aMonthForward.setSeconds(59);

    if (aMonthForward < licenceDate) return true;

    return false;
  }
  createTrialLicence() {
    const { dispatch, match } = this.props;
    dispatch(getTrialLicence(match.params.orgId));
  }
  createActivitiesLicence() {
    const { dispatch, match } = this.props;
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    aYearFromNow.setHours(23);
    aYearFromNow.setMinutes(59);
    aYearFromNow.setSeconds(59);
    dispatch(
      createLicence(
        'com.kudosapps.boards.lite',
        'number',
        aYearFromNow,
        null,
        match.params.orgId
      )
    );
  }
  getOrg() {
    const { dispatch, match } = this.props;
    if (match.params.orgId)
      dispatch(getOrg(match.params.orgId)).then((org) =>
        this.setState({
          contactName: org.contact.name,
          contactPhone: org.contact.phone,
          contactAddress: org.contact.address,
          contactWebsite: org.contact.website,
        })
      );
    else dispatch(getOrgs(false));
  }
  inviteOrgAdmin() {
    const { org, dispatch } = this.props;
    if (this.state.inviteEmail) {
      this.setState({ inviteEmailError: false, inviteEmailErrorMsg: '' });
      dispatch(inviteOrgAdmin(org._id, this.state.inviteEmail));
    } else {
      this.setState({
        inviteEmailError: true,
        inviteEmailErrorMsg: 'User Email required',
      });
    }
  }
  getOrgAdmins() {
    const { dispatch, match } = this.props;
    if (match.params.orgId) dispatch(getOrgAdmins(match.params.orgId));
  }
  getLicences() {
    const { dispatch, match } = this.props;
    if (match.params.orgId) dispatch(getLicences(match.params.orgId));
  }
  togglePartner() {
    const { org, dispatch } = this.props;
    dispatch(togglePartner(org._id, !org.isPartner));
  }
  makePrimary() {
    const { org, dispatch } = this.props;
    dispatch(updateUser(null, null, { primaryOrg: org.id })).then(() =>
      this.setState({ currentPrimaryOrg: true })
    );
  }
  updateOrg() {
    const valid = this.validateForm();

    if (valid) {
      const contact = {
        name: this.state.contactName,
        phone: this.state.contactPhone,
        address: this.state.contactAddress,
        website: this.state.contactWebsite,
      };
      const env = { appURI: this.state.envUrl, clients: this.state.clients };
      this.props
        .dispatch(updateOrg(this.state.name, false, null, contact, env))
        .then(() => {
          this.setState({ redirect: '/orgs' });
        });
    }

    return valid;
  }
  validateName() {
    if (!this.state.name) {
      this.setState({ nameError: true, nameErrorMsg: 'Your name is required' });
      return false;
    }
    this.setState({ nameError: false, nameErrorMsg: '' });
    return true;
  }
  validateContactName() {
    if (!this.state.contactName) {
      this.setState({
        contactNameError: true,
        contactNameErrorMsg: 'Contact name is required',
      });
      return false;
    }
    this.setState({ contactNameError: false, contactNameErrorMsg: '' });
    return true;
  }
  validateContactPhone() {
    // if(!this.state.contactPhone)
    // {
    //   this.setState({contactPhoneError:true, contactPhoneErrorMsg:"Contact phone is required"});
    //   return false;''
    // }
    this.setState({ contactPhoneError: false, contactPhoneErrorMsg: '' });
    return true;
  }
  validateContactEmail() {
    if (!this.state.contactAddress) {
      this.setState({
        contactAddressError: true,
        contactAddressErrorMsg: 'Contact Email is required',
      });
      return false;
    }
    this.setState({ contactAddressError: false, contactAddressErrorMsg: '' });
    return true;
  }
  validateContactWebsite() {
    // if(!this.state.contactWebsite)
    // {
    //   this.setState({contactWebsiteError:true, contactWebsiteErrorMsg:"Contact Website is required"});
    //   return false;
    // }
    this.setState({ contactWebsiteError: false, contactWebsiteErrorMsg: '' });
    return true;
  }
  validateClient() {
    // if (this.state.clients.length === 0) {
    //   this.setState({
    //     envClientError: true,
    //     envClientErrorMsg: 'Client details are required',
    //   });
    //   return false;
    // }
    this.setState({ envClientError: false, envClientErrorMsg: '' });
    return true;
  }
  validateForm() {
    const nameCorrect = this.validateName();
    const contactNameCorrect = this.validateContactName();
    const contactPhoneCorrect = this.validateContactPhone();
    const contactEmailCorrect = this.validateContactEmail();
    const contactWebsiteCorrect = this.validateContactWebsite();
    const clientCorrect = this.validateClient();

    return (
      nameCorrect &&
      contactNameCorrect &&
      contactPhoneCorrect &&
      contactEmailCorrect &&
      contactWebsiteCorrect &&
      clientCorrect
    );
  }

  // eslint-disable-next-line class-methods-use-this
  fixUrl(url) {
    url = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
    return url;
  }

  showAddDialog = () => {
    //const { envUrl } = this.state;
    //const encodedString = btoa(envUrl);
    this.setState({
      envClientProvider: 'connections',
      envClientURL: '',
      envClientExternalId: '',
    });
    this.setState({ addDialogVisible: true });
  };

  hideAddDialog = () => {
    this.setState({ addDialogVisible: false });

    this.setState({
      envClientExternalIdError: false,
      envClientExternalIdErrorMsg: '',
      envClientProviderError: false,
      envClientProviderErrorMsg: '',
      envClientURLError: false,
      envClientURLErrorMsg: '',
    });
  };
  addClient = () => {
    const { org } = this.props;
    const { envClientProvider, envClientURL, envClientExternalId } = this.state;
    let valid = true;
    let clients;

    if (!envClientProvider) {
      this.setState({
        envClientProviderError: true,
        envClientProviderErrorMsg: 'This is required',
      });
      valid = false;
    } else {
      this.setState({
        envClientProviderError: false,
        envClientProviderErrorMsg: '',
      });
    }

    if (['connections', 'domino', 'dx', 'msad'].includes(envClientProvider)) {
      if (!envClientURL) {
        this.setState({
          envClientURLError: true,
          envClientURLErrorMsg: 'This is required',
        });
        valid = false;
      } else {
        this.setState({
          envClientURLError: false,
          envClientURLErrorMsg: '',
          envClientURL: this.fixUrl(envClientURL),
        });
      }
    }

    if (envClientProvider === 'msgraph') {
      if (!envClientExternalId) {
        this.setState({
          envClientExternalIdError: true,
          envClientExternalIdErrorMsg: 'This is required',
        });
        valid = false;
      } else {
        this.setState({
          envClientExternalIdError: false,
          envClientExternalIdErrorMsg: '',
        });
      }
    }
    if (valid) {
      const encodedString = btoa(this.fixUrl(envClientURL));
      const externalId =
        envClientProvider === 'msgraph' ? envClientExternalId : encodedString;

      clients = org.env.clients;

      clients.push({
        provider: envClientProvider,
        url: this.fixUrl(envClientURL),
        externalId,
      });
      this.setState({ clients });

      const env = { appURI: org.env.appURI, clients };

      this.props.dispatch(updateOrg(org._id, null, null, null, env));

      this.hideAddDialog();
    }
  };
  render() {
    const { org, licences, user, orgadmins, match } = this.props;
    const {
      contactName,
      contactPhone,
      contactAddress,
      contactWebsite,
      envClientProvider,
      envClientURL,
      envClientExternalId,
      primaryOrgExists,
      inviteEmail,
      currentPrimaryOrg,
    } = this.state;

    console.log(this.state.clients);

    const actions = [];
    actions.push({
      secondary: true,
      children: 'Cancel',
      onClick: this.hideAddDialog,
    });
    actions.push(
      <Button flat primary onClick={this.addClient}>
        Add
      </Button>
    );

    if (!org) {
      if (primaryOrgExists || match.params.orgId) return 'loading...';

      return <Redirect to={'/createorg'} />;
    }

    let licenceTableBody,
      orgAdminBody,
      existingAPLicence,
      existingTrial,
      clientListItems;

    const mailtoLink =
      'mailto:hello@huddo.com?subject=Huddo Boards Enquiry&body=Hi, my name is ' +
      user.name +
      ' and my organisation ' +
      org.name +
      ' is interested in the full Huddo Boards experience.';

    if (org.env) {
      const { clients } = org.env;
      if (clients) {
        clientListItems = clients.map((client, i) => (
          <ListItem
            key={i}
            disabled
            primaryText={clientProviderView[client.provider]}
            secondaryText={
              client.provider === 'msgraph' ? client.externalId : client.url
            }
            rightIcon={
              <Button
                icon
                onClick={() => {
                  clients.splice(i, 1);
                  this.setState({ clients });
                  this.props.dispatch(
                    updateOrg(org._id, null, null, null, org.env)
                  );
                }}
              >
                <FontIcon>delete</FontIcon>
              </Button>
            }
          />
        ));
      }
    }

    const allowedActivitiesPlus = org?.env?.clients?.some((client) =>
      isActivitiesPlus(client.provider)
    );

    const allowedFree = !!org?.env?.clients?.length && !allowedActivitiesPlus;

    if (licences) {
      licenceTableBody = (
        <TableBody>
          {licences.map((licence) => (
            <TableRow key={licence.id}>
              <TableColumn>{licence.productID}</TableColumn>
              <TableColumn>{licence.type}</TableColumn>
              <TableColumn>
                {licence.limit ? licence.limit : 'Unlimited'}
              </TableColumn>
              <TableColumn>{licence.expiry}</TableColumn>
            </TableRow>
          ))}
        </TableBody>
      );
    }

    if (orgadmins) {
      orgAdminBody = (
        <TableBody>
          {orgadmins.map((admin) => (
            <TableRow key={admin.id}>
              <TableColumn>{admin.user?.name}</TableColumn>
              <TableColumn>{admin.email}</TableColumn>
            </TableRow>
          ))}
        </TableBody>
      );
    }

    //check if there is an existing trial
    for (const [index] of licences.entries()) {
      if (licences[index].productID === 'com.kudosapps.boards.premium')
        existingTrial = true;
    }

    //check if there is an existing activities plus licence
    for (const [index] of licences.entries()) {
      if (licences[index].productID === 'com.kudosapps.boards.lite')
        existingAPLicence = this.checkActivitiesLicenceExpiry(
          licences[index].expiry
        );
      if (existingAPLicence) break;
    }

    // let trialButton;

    // if (!existingTrial) {
    //   trialButton = (
    //     <Button
    //       primary
    //       raised
    //       //href="/createlicence"
    //       onClick={this.createTrialLicence}
    //       children="Create Trial Licence"
    //     />
    //   );
    // }

    return (
      <div className="org-detail">
        <div id="ks-org">
          <div id="ks-orgDetails">
            {org.isPartner ? (
              <div className="ks-partner">Huddo Partner Org</div>
            ) : null}
            <Subheader
              primary
              component="div"
              primaryText={
                <div>
                  Organisation Details
                  <span className="sub-sub">
                    {this.props.match.params.orgId}
                  </span>
                </div>
              }
            />
            <TextField
              floating
              id="floating-center-title"
              label="Name"
              lineDirection="center"
              defaultValue={org.name}
              fullWidth
              disabled
              //onChange={name => this.setState({ name })}
            />
            <TextField
              floating
              id="employee-count"
              label="Number of Employees"
              lineDirection="center"
              value={org.employeeCount}
              fullWidth
              disabled
              //onChange={name => this.setState({ name })}
            />
            <TextField
              floating
              id="floating-center-title"
              label="Boards Production Hostname and Path"
              lineDirection="center"
              disabled
              defaultValue={org.env ? org.env.appURI : ''}
              fullWidth
            />
            {!currentPrimaryOrg ? (
              <Button
                className="make-primary"
                primary
                raised
                children="Make Primary"
                onClick={this.makePrimary}
              />
            ) : null}
            <Subheader primary component="div" primaryText="Main Contact" />
            <TextField
              floating
              id="floating-center-title"
              label="Name"
              lineDirection="center"
              required={true}
              value={contactName}
              onChange={(contactName) => this.setState({ contactName })}
              fullWidth
              disabled
            />
            <TextField
              floating
              id="floating-center-title"
              label="Phone Number"
              lineDirection="center"
              value={contactPhone}
              onChange={(contactPhone) => this.setState({ contactPhone })}
              fullWidth
              disabled
            />
            <TextField
              floating
              id="floating-center-title"
              label="Email"
              lineDirection="center"
              required={true}
              value={contactAddress}
              onChange={(contactAddress) => this.setState({ contactAddress })}
              fullWidth
              disabled
            />
            <TextField
              floating
              id="floating-center-title"
              label="Website"
              lineDirection="center"
              value={contactWebsite}
              onChange={(contactWebsite) => this.setState({ contactWebsite })}
              fullWidth
              disabled
            />
            {user.isAdmin ? (
              <div>
                <Subheader
                  primary
                  component="div"
                  primaryText="Partner details"
                />
                <Button
                  primary
                  raised
                  children={org.isPartner ? 'Revoke Partner' : 'Make Partner'}
                  onClick={this.togglePartner}
                />
              </div>
            ) : null}

            <Subheader primary component="div" primaryText="Clients" />
            <Button
              flat
              onClick={this.showAddDialog}
              iconBefore
              iconEl={<FontIcon>add</FontIcon>}
            >
              Add Client
            </Button>
            <DialogContainer
              width={600}
              contentStyle={{ height: 400 }}
              id="simple-action-dialog"
              visible={this.state.addDialogVisible}
              onHide={this.hideAddDialog}
              actions={actions}
              title="Add Client"
            >
              <SelectField
                id="select-field-2"
                label="Provider"
                error={this.state.envClientProviderError}
                errorText={this.state.envClientProviderErrorMsg}
                menuItems={clientProvider}
                value={envClientProvider}
                fullWidth
                onChange={(envClientProvider) =>
                  this.setState({ envClientProvider })
                }
                onVisibilityChange={this.changeProvider}
              />
              <TextField
                id="simple-action-dialog-field"
                label={'Hostname'}
                className={
                  envClientProvider === 'msgraph' ? 'ks-hide' : 'ks-show'
                }
                error={this.state.envClientURLError}
                errorText={this.state.envClientURLErrorMsg}
                placeholder={envClientProvider + '.company.com'}
                value={envClientURL}
                fullWidth
                onChange={(value) => {
                  this.setState({
                    envClientURL: value.replace(/[^a-zA-Z0-9.-]/, ''),
                  });
                }}
              />
              <TextField
                floating
                id="simple-action-dialog-field"
                label="Tenant ID"
                className={
                  envClientProvider === 'msgraph' ? 'ks-show' : 'ks-hide'
                }
                error={this.state.envClientExternalIdError}
                errorText={this.state.envClientExternalIdErrorMsg}
                value={envClientExternalId}
                fullWidth
                onChange={(envClientExternalId) =>
                  this.setState({ envClientExternalId })
                }
              />
            </DialogContainer>
            <List>{clientListItems}</List>
            {/* <Button
              primary
              raised
              onClick={this.updateOrg}
              className="update-org-button"
              children="Update Org"
            /> */}
          </div>
          <div id="ks-orgLicences">
            <Subheader primary component="div" primaryText="Licences" />
            <DataTable plain>
              <TableHeader>
                <TableRow>
                  <TableColumn grow>Product</TableColumn>
                  <TableColumn>Type</TableColumn>
                  <TableColumn>Number of Users</TableColumn>
                  <TableColumn>Expiry</TableColumn>
                </TableRow>
              </TableHeader>
              {licenceTableBody}
            </DataTable>
            <DownloadLicenceDialog
              // eslint-disable-next-line max-len
              url={`${config.services.api}/org/${this.props.match.params.orgId}/download`}
              visible={this.state.downloadLicenceDialogVisible}
              onClose={() =>
                this.setState({ downloadLicenceDialogVisible: false })
              }
            />
            <div className="right-buttons">
              {licences.length !== 0 ? (
                <>
                  <Button
                    primary
                    raised
                    children="Download Licences"
                    onClick={() => {
                      this.setState({ downloadLicenceDialogVisible: true });
                    }}
                  />
                </>
              ) : (
                ''
              )}{' '}
              <Button
                primary
                raised
                href={mailtoLink}
                children={
                  allowedActivitiesPlus
                    ? 'Request Huddo Boards Licences'
                    : 'Contact us for a quote'
                }
              />{' '}
              {allowedActivitiesPlus && !existingAPLicence && (
                <>
                  <Button
                    primary
                    raised
                    onClick={this.createActivitiesLicence}
                    children={'Create Activities+ Licence'}
                  />{' '}
                </>
              )}
              {allowedFree && !existingTrial && (
                <>
                  <Button
                    primary
                    raised
                    onClick={this.createTrialLicence}
                    children={'Create Trial Licence'}
                  />{' '}
                </>
              )}
              {user.isAdmin ? (
                <Button
                  primary
                  raised
                  href={`/createlicence/${this.props.match.params.orgId}`}
                  children="Create Licence"
                />
              ) : null}
            </div>
          </div>
        </div>
        <div id="ks-orgUsers">
          <Subheader primary component="div" primaryText="Users" />
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn grow>Name</TableColumn>
                <TableColumn>Email</TableColumn>
              </TableRow>
            </TableHeader>
            {orgAdminBody}
          </DataTable>
          <TextField
            floating
            id="simple-action-dialog-field"
            label="User Email"
            error={this.state.inviteEmailError}
            errorText={this.state.inviteEmailErrorMsg}
            value={inviteEmail}
            fullWidth
            onChange={(inviteEmail) => this.setState({ inviteEmail })}
          />
          <Button
            primary
            raised
            onClick={this.inviteOrgAdmin}
            children="Invite Org Admin"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  { user, orgs, licences, orgadmins },
  {
    match: {
      params: { orgId },
    },
  }
) => ({
  user,
  org: orgs.find((org) => org._id === orgId),
  licences,
  orgadmins,
});
export default connect(mapStateToProps)(Org);
