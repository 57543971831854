import * as API from './LicencesAPI';

export const ADD_LICENCES = 'licence/ADD_LICENCES';
export const ADD_LICENCE = 'licence/ADD_LICENCE';
export const GET_LICENCE = 'licence/GET_LICENCE';
export const CLEAR_LICENCES = 'licence/CLEAR_LICENCES';
export const ADD_DOWNLOAD = 'licence/ADD_DOWNLOAD';

function addLicences(licences) {
  return {
    type: ADD_LICENCES,
    licences,
  };
}

function clearLicences() {
  return {
    type: CLEAR_LICENCES,
  };
}

function addLicence(licence) {
  return {
    type: ADD_LICENCE,
    licence,
  };
}

export function getLicences(orgId) {
  return (dispatch) => {
    API.licences(orgId).then((licences) => {
      if (licences) {
        dispatch(addLicences(licences));
      }
    });
  };
}

/* export function getLicence(id) {
 *   return dispatch => {
 *     API.org(id).then(org =>{
 *       if (org) {
 *         dispatch(addLicence(org));
 *       }
 *     });
 *   };
 * } */

export function createLicence(productID, type, expiry, limit, orgId) {
  return (dispatch) =>
    API.create(productID, type, expiry, limit, orgId).then((licence) => {
      dispatch(addLicence(licence));
      return licence;
    });
}

export function getTrialLicence(orgId) {
  return (dispatch) =>
    API.getTrial(orgId).then((licence) => {
      dispatch(addLicence(licence));
      return licence;
    });
}

export function licencesClear() {
  clearLicences();
}
