import callApi from '../../util/apiCaller';

export function create(productID, type, expiry, limit, orgId) {
  return callApi('org/' + orgId + '/licence', 'post', {
    productID,
    type,
    expiry,
    limit,
  });
}

export function getTrial(orgId) {
  return callApi(`org/${orgId}/licence/trial`);
}

export function licences(orgId) {
  return callApi('org/' + orgId + '/licence');
}

export function org(id) {
  return callApi('org/' + id);
}

export function download(orgId) {
  const url = 'org/' + orgId + '/download';
  return callApi(url);
}
