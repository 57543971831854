import * as API from './OrgAdminAPI';
// import * as userAPI from './UserAPI';

export const ADD_ORGADMINS = 'orgadmins/ADD_ORGADMINS';
export const ADD_ORGADMIN = 'orgadmins/ADD_ORGADMIN';
export const REMOVE_ORGADMINS = 'orgadmins/REMOVE_ORGADMINS';
export const REMOVE_INVITE = 'user/REMOVE_INVITE';

function addOrgAdmins(orgAdmins) {
  return {
    type: ADD_ORGADMINS,
    orgAdmins,
  };
}

export function addOrgAdmin(orgAdmin) {
  return {
    type: ADD_ORGADMIN,
    orgAdmin,
  };
}

function removeOrgAdmins(user) {
  return {
    type: REMOVE_ORGADMINS,
    user,
  };
}

function removeInvite(invite) {
  return {
    type: REMOVE_INVITE,
    invite,
  };
}

export function getOrgAdmins(orgId) {
  return (dispatch) =>
    API.all(orgId).then((orgAdmins) => {
      if (orgAdmins) {
        dispatch(addOrgAdmins(orgAdmins));
      }
    });
}

export function inviteOrgAdmin(orgId, email) {
  return (dispatch) => API.invite(orgId, email).then();
}

export function acceptInvite(orgId, adminId) {
  return (dispatch) =>
    API.accept(orgId, adminId).then(dispatch(removeInvite(adminId)));
}

export function deleteOrgAdmins(orgId) {
  return (dispatch) =>
    API.all(orgId).then((orgAdmins) => {
      if (orgAdmins) {
        dispatch(removeOrgAdmins(orgAdmins));
      }
    });
}
