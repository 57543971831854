import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
  SelectField,
} from 'react-md';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import config from '../../../config';
import { getLicences } from '../LicenceActions';
import { getOrgs } from '../OrgActions';

class Licences extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {
    org: '',
  };
  constructor(props) {
    super(props);
    this.getOrgs = this.getOrgs.bind(this);
    this.getLicences = this.getLicences.bind(this);
  }
  getOrgs() {
    const { user } = this.props;
    this.props.dispatch(getOrgs(user.isAdmin));
  }
  getLicences(org) {
    this.props.dispatch(getLicences(org));
  }
  componentDidMount() {
    //const { orgList, envUrl, contactName, contactPhone, contactAddress, contactWebsite } = this.state;
    this.getOrgs();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.org !== prevState.org) {
      //this.setState({licences: ''});
      this.getLicences(this.state.org);
    }
  }
  render() {
    const { licences, orgs, user } = this.props;
    const { org } = this.state;

    if (!user.isAdmin) return <Redirect to="/home" />;

    const orgList = [];
    if (orgs) {
      Object.keys(orgs).map((_, i) =>
        orgList.push({
          label: orgs[i].name,
          value: orgs[i].id,
        })
      );
    }

    let tableBody;

    if (licences) {
      tableBody = (
        <TableBody>
          {Object.keys(licences).map((_, i) => (
            <TableRow key={i}>
              <TableColumn>{licences[i].productID}</TableColumn>
              <TableColumn>{licences[i].type}</TableColumn>
              <TableColumn>{licences[i].limit}</TableColumn>
            </TableRow>
          ))}
        </TableBody>
      );
    }

    return (
      <div>
        <br />
        <SelectField
          id="ks-org"
          label="Organisation"
          className="md-cell"
          //defaultValue={orgList[0] ? orgList[0].value : ''}
          menuItems={orgList}
          value={org}
          onChange={(org) => this.setState({ org })}
        />
        <br />
        <DataTable plain>
          <TableHeader>
            <TableRow>
              <TableColumn>Product</TableColumn>
              <TableColumn>Type</TableColumn>
              <TableColumn>Number</TableColumn>
            </TableRow>
          </TableHeader>
          {tableBody}
        </DataTable>
        <Button
          primary
          raised
          href={`${config.services.api}/org/${this.state.org}/download`}
          children="Download Licence"
        />
      </div>
    );
  }
}

const mapStateToProps = ({ orgs, licences, user }) => ({
  orgs,
  licences,
  user,
});
export default connect(mapStateToProps)(Licences);
