import {
  ADD_ORGADMINS,
  ADD_ORGADMIN,
  REMOVE_ORGADMINS,
} from './OrgAdminActions';

const defaultState = [];

export default function orgAdminReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_ORGADMINS: {
      return action.orgAdmins;
    }
    case ADD_ORGADMIN: {
      return [action.orgAdmin];
    }
    case REMOVE_ORGADMINS: {
      return {};
    }
    default: {
      return state;
    }
  }
}
