import { ADD_ORGS, ADD_ORG, UPDATE_ORG } from './OrgActions';

const defaultState = [];

export default function orgsReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_ORGS: {
      return action.orgs;
    }
    case ADD_ORG: {
      return [...state.filter((org) => org._id !== action.org._id), action.org];
    }
    case UPDATE_ORG: {
      return [
        ...state.map((org) => {
          if (org._id === action.id) {
            return {
              ...org,
              ...action.delta,
            };
          }
          return org;
        }),
      ];
    }
    default: {
      return state;
    }
  }
}
