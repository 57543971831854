import * as API from './UserAPI';

export const ADD_USER = 'user/ADD_USER';
export const ADD_USERS = 'user/ADD_USERS';
export const GET_USER = 'user/GET_USER';
export const REMOVE_USER = 'user/REMOVE_USER';
export const REGISTER = 'user/REGISTER';
export const ADD_INVITES = 'user/USER_INVITES';
export const REMOVE_INVITE = 'user/REMOVE_INVITE';

function addUser(user) {
  return {
    type: ADD_USER,
    user,
  };
}

function addUsers(users) {
  return {
    type: ADD_USERS,
    users,
  };
}

function removeUser(user) {
  return {
    type: REMOVE_USER,
    user,
  };
}

function addInvites(invites) {
  return {
    type: ADD_INVITES,
    invites,
  };
}

export function removeInvite(invite) {
  return {
    type: REMOVE_INVITE,
    invite,
  };
}

export function getCurrentUser() {
  return (dispatch) =>
    API.me().then((user) => {
      if (user) {
        dispatch(addUser(user));
      }
      return user;
    });
}

export function register(name, email, password, info) {
  return (dispatch) => API.register(name, email.toLowerCase(), password, info);
}

export function verify(tokenId) {
  return (dispatch) => API.verify(tokenId);
}

export function resendVerify(email) {
  return (dispatch) => {
    API.resendVerify(email.toLowerCase());
  };
}

export function login(email, password) {
  return (dispatch) =>
    API.login(email.toLowerCase(), password).then((user) => {
      API.me().then((user) => {
        if (user) {
          dispatch(addUser(user));
        }
      });
    });
}

export function updateUser(name, password, info) {
  return (dispatch) =>
    API.update(name, password, info).then((user) => {
      dispatch(addUser(user));
      return user;
    });
}

export function logout() {
  return (dispatch) => {
    API.logout().then(dispatch(removeUser({})));
  };
}

export function forgotPassword(email) {
  return (dispatch) => API.forgotPassword(email.toLowerCase());
}

export function resetPassword(token, password) {
  return (dispatch) => API.resetPassword(token, password);
}

export function listUsers() {
  return (dispatch) => {
    API.listUsers().then((users) => {
      dispatch(addUsers(users));
    });
  };
}

export function getMyInvites() {
  return (dispatch) =>
    API.getMyInvites().then((invites) => {
      if (invites) {
        dispatch(addInvites(invites));
      }
      return invites;
    });
}
