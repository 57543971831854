// disabling this so we have the potential to track referrer
/* eslint-disable react/jsx-no-target-blank */
import Form from './Form';

import './index.scss';

const BoardsExtensions = () => (
  <div className="bext-root">
    <div className="bext-blurb">
      <h3>Boards Extensions</h3>
      <p>
        This form will create you a customised package of extension files for
        adding Huddo Boards features to <b>HCL Connections</b> and{' '}
        <b>HCL Verse</b>. For details on how to use these files, see:
        <ul>
          <li>
            <a
              href="https://docs.huddo.com/boards/connections/customizer/customizer-integrations-package/"
              target="_blank"
            >
              HCL Connections
            </a>
          </li>
          <li>
            <a
              href="https://docs.huddo.com/boards/verse/verse-extension/"
              target="_blank"
            >
              HCL Verse
            </a>
          </li>
        </ul>
      </p>
    </div>
    <Form />
  </div>
);
export default BoardsExtensions;
