import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

// const initialState = {};
const enhancers = [];
const middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
  const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof reduxDevTools === 'function') {
    enhancers.push(reduxDevTools());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(
  rootReducer,
  { config: window.config },
  composedEnhancers
);

export default store;
