import { Route, Switch } from 'react-router-dom';

import GenericRoutes from './GenericRoutes';
import Logout from './modules/User/pages/Logout';
import Verify from './modules/User/pages/Verify';

function AppRouter() {
  return (
    <Switch>
      <Route path="/logout" exact component={Logout} />
      <Route path="/user/verify" exact component={Verify} />
      {GenericRoutes}
      <Route component={Verify} />
    </Switch>
  );
}

export default AppRouter;
