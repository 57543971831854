import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import { listUsers } from '../UserActions';

import './Orgs.scss';

class Users extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = { userIsPartner: false };
  constructor(props) {
    super(props);
    this.getUsers = this.getUsers.bind(this);
  }
  getUsers() {
    this.props.dispatch(listUsers());
  }
  componentDidMount() {
    this._isMounted = true;
    this.getUsers();
  }

  render() {
    const { user, users } = this.props;

    if (!user.isAdmin) return <Redirect to="/home" />;

    return (
      <div className="org-list">
        <DataTable plain>
          <TableHeader>
            <TableRow>
              <TableColumn>Name</TableColumn>
              <TableColumn>Email</TableColumn>
              <TableColumn>Primary Org</TableColumn>
              <TableColumn>Partner</TableColumn>
              <TableColumn>Admin</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.keys(users).map((user) => (
              <TableRow>
                <TableColumn>{user?.name}</TableColumn>
                <TableColumn>{user?.email}</TableColumn>
                <TableColumn>
                  {
                    <Link to={'/org/' + user?.info?.primaryOrg}>
                      {user?.info?.primaryOrg}
                    </Link>
                  }
                </TableColumn>
                <TableColumn>
                  {user?.info?.isPartner ? 'Partner' : 'Client'}
                </TableColumn>
                <TableColumn>{user?.isAdmin ? 'Admin' : 'User'}</TableColumn>
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
      </div>
    );
  }
}

const mapStateToProps = ({ user, users }) => ({ user, users });
export default connect(mapStateToProps)(Users);
