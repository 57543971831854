import PropTypes from 'prop-types';
import { Component } from 'react';
import { TextField, Button, Subheader } from 'react-md';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { forgotPassword, resetPassword } from '../UserActions';

class ForgotPassword extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {
    token: '',
    tokenError: false,

    redirect: '',
    email: '',
    password: '',
    secondPassword: '',
    emailError: false,
    emailErrorMsg: 'Email is required',
    passwordError: false,
    passwordErrorMsg: '',
    secondPasswordError: false,
    secondPasswordErrorMsg: '',
  };
  constructor(props) {
    super(props);
    this.requestReset = this.requestReset.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateSecondPassword = this.validateSecondPassword.bind(this);
    this.validatePasswords = this.validatePasswords.bind(this);
  }
  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    this.setState({ token });
  }
  requestReset(e) {
    const { email } = this.state;
    e.preventDefault();
    const valid = this.validateEmail();
    if (valid) {
      this.props
        .dispatch(forgotPassword(email))
        .then(() => {
          //this.setState({ redirect: '/login' });
          this.setState({ submitSuccess: true });
        })
        .catch(() => {
          this.setState({ submitSuccess: false });
        });
    }
  }
  resetPassword(e) {
    const { token, password } = this.state;
    e.preventDefault();
    const valid = this.validatePasswords();
    if (valid) {
      this.props
        .dispatch(resetPassword(token, password))
        .then(() => {
          this.setState({ redirect: '/login' });
        })
        .catch(() => {
          this.setState({ tokenError: true });
        });
    }
  }
  validateEmail() {
    if (!this.state.email) {
      this.setState({
        emailError: true,
        emailErrorMsg: 'Your email is required',
      });
      return false;
    }
    this.setState({ emailError: false, emailErrorMsg: '' });
    return true;
  }
  validatePassword() {
    if (!this.state.password) {
      this.setState({
        passwordError: true,
        passwordErrorMsg: 'Password is required',
      });
      return false;
    }
    this.setState({ passwordError: false, passwordErrorMsg: '' });
    return true;
  }
  validateSecondPassword() {
    if (!this.state.secondPassword) {
      this.setState({
        secondPasswordError: true,
        secondPasswordErrorMsg: 'Password confirmation is required',
      });
      return false;
    } else if (this.state.password && this.state.secondPassword) {
      if (this.state.password !== this.state.secondPassword) {
        this.setState({
          secondPasswordError: true,
          secondPasswordErrorMsg: 'Passwords must match',
        });
        return false;
      }
      this.setState({ secondPasswordError: false, secondPasswordErrorMsg: '' });
    }
    return true;
  }
  validatePasswords() {
    const passwordCorrect = this.validatePassword();
    const secondPasswordCorrect = this.validateSecondPassword();

    return passwordCorrect && secondPasswordCorrect;
  }

  render() {
    const {
      token,
      tokenError,
      email,
      redirect,
      password,
      secondPassword,
      submitSuccess,
    } = this.state;

    if (submitSuccess) {
      return (
        <div>
          <div>Password reset email sent. Please check your email.</div>
          <Link to="/login">Login</Link> <Link to="/register">Register</Link>
        </div>
      );
    }

    if (redirect) return <Redirect to={redirect} />;

    return (
      <div>
        <h1>Welcome to the Huddo Store</h1>
        Huddo Boards Premium View lets you enhance your Activities Plus work
        space. View your Activities as Boards, MindMaps, Timelines and drive
        collaborative team work. <br />
        Enable Huddo Boards Premium View for your organisation today.{' '}
        <Link to="/login">Login</Link> or <Link to="/register">Register</Link>{' '}
        to get started.
        <br />
        <br />
        <Subheader primary component="div" primaryText="Forgot Password" />
        <div className={token ? 'ks-hide' : 'ks-show'}>
          <form onSubmit={this.requestReset}>
            <TextField
              id="floating-center-title"
              label="Email"
              type="email"
              lineDirection="center"
              error={this.state.emailError}
              errorText={this.state.emailErrorMsg}
              value={email}
              onChange={(email) => this.setState({ email })}
              onBlur={this.validateEmail}
            />
            <Button primary raised type="submit" children="Request" />
          </form>
        </div>
        <div className={token ? 'ks-show' : 'ks-hide'}>
          <div
            style={{ color: 'red' }}
            className={tokenError ? 'ks-show' : 'ks-hide'}
          >
            Email reset failed.
          </div>
          <form onSubmit={this.resetPassword}>
            <TextField
              id="ks-password"
              label="Enter your password"
              type="password"
              error={this.state.passwordError}
              errorText={this.state.passwordErrorMsg}
              value={password}
              onChange={(password) => this.setState({ password })}
              onBlur={this.validatePassword}
            />
            <TextField
              id="ks-secondpassword"
              label="Reenter your password"
              type="password"
              error={this.state.secondPasswordError}
              errorText={this.state.secondPasswordErrorMsg}
              value={secondPassword}
              onChange={(secondPassword) => this.setState({ secondPassword })}
              onBlur={this.validateSecondPassword}
            />
            <Button primary raised type="submit" children="Reset" />
          </form>
        </div>
        <Link to="/login">Login</Link> <Link to="/register">Register</Link>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

// function _mapStateToProps(state) {
//   const { user } = state; // const user = state.user
//   return { user };
//   // return {
//     // user: user,
//   // };
// }
// // const mapStateToProps = _mapStateToProps.bind(this);

export default connect(mapStateToProps)(ForgotPassword);
