import config from '../config';

function stripLeadingSlash(url) {
  if (url.startsWith('/')) return url.substr(1);
  return url;
}

export default function callApi(
  endpoint,
  method = 'get',
  body,
  fetchParams = {}
) {
  /* eslint no-param-reassign: off */
  const headers = {
    'content-type': 'application/json',
  };

  if (typeof body === 'object') {
    body = JSON.stringify(body);
  }

  return fetch(`${config.services.api}/${stripLeadingSlash(endpoint)}`, {
    headers,
    method,
    body,
    ...fetchParams,
    credentials: 'include',
  }).then(
    (response) => {
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          return Promise.reject();
        }
        return Promise.reject({
          status: response.status,
          statusText: response.statusText,
        });
      }
      // no content
      if (response.status === 204) {
        return {};
      }
      return (
        response
          .json()
          //automatically convert date strings to date objects
          //http://aboutcode.net/2013/07/27/json-date-parsing-angularjs.html
          .catch(() => ({}))
      );
    },
    () => Promise.reject()
  );
}
