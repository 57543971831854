import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { acceptInvite } from '../OrgAdminActions';

class Verify extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  state = {
    redirect: '',
  };
  componentDidMount() {
    const orgId = this.props.match.params.orgId;
    const adminId = this.props.match.params.adminId;

    this.props
      .dispatch(acceptInvite(orgId, adminId))
      .then(
        setTimeout(() => this.setState({ redirect: '/org/' + orgId }), 3000)
      );
  }
  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return <div>Accepting invite...</div>;
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(Verify);
