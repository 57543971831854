import copy from 'copy-to-clipboard';
import { useCallback, useEffect, useState } from 'react';
import { Card, CardActions, CardText, CardTitle, TextField } from 'react-md';

import config from '../../../../config';
import { useOrgs } from '../../useOrgs';
import DownloadButton from './DownloadButton';
import FormInputs from './FormInputs';
import FormPrefill from './FormPrefill';
import ShellButton from './ShellButton';

const boardsURLDefault = 'https://';
const verseURLDefault = 'https://';
const authPathDefault = '/auth/connections';

const Form = () => {
  const [boardsURL, setBoardsURL] = useState(boardsURLDefault);
  const [verseURL, setVerseURL] = useState(verseURLDefault);
  const [linkId, setLinkId] = useState(
    /** @type {string|undefined} */ (undefined)
  );
  const [copySuccess, setCopySuccess] = useState(false);
  const [authPath, setAuthPath] = useState(authPathDefault);

  const orgs = useOrgs();

  useEffect(() => {
    setLinkId(undefined);
    setCopySuccess(false);
  }, [/* effect dep */ boardsURL, /* effect dep */ verseURL]);

  /**
   * @type {(value: { boardsURL?:string, authPath?: string, verseURL?:string }) => void }
   */
  const handleInputChange = useCallback(({ boardsURL, authPath, verseURL }) => {
    setBoardsURL(boardsURL || boardsURLDefault);
    setAuthPath(authPath || authPathDefault);
    setVerseURL(verseURL || verseURLDefault);
  }, []);

  return (
    <form className="bext-form">
      <Card>
        <CardTitle style={{ paddingBottom: 0 }} title="Prefill" />
        <CardText style={{ paddingTop: 0 }}>
          <FormPrefill orgs={orgs || []} onChange={handleInputChange} />
        </CardText>
      </Card>
      <br />
      <Card>
        <CardText>
          <FormInputs
            orgs={orgs || []}
            verseURL={verseURL}
            boardsURL={boardsURL}
            authPath={authPath}
            onChange={handleInputChange}
          />
        </CardText>

        <CardActions>
          <div className="form-buttons">
            <DownloadButton
              boardsURL={boardsURL}
              verseURL={verseURL}
              authPath={authPath}
            />
            <ShellButton
              boardsURL={boardsURL}
              verseURL={verseURL}
              authPath={authPath}
              onLinkCreated={useCallback((linkId) => {
                setLinkId(linkId);
              }, [])}
            />
          </div>
        </CardActions>
        {linkId && (
          <CardActions>
            <TextField
              rows={2}
              value={`curl ${config.services.api}/bext/link/${linkId} | tar -xz`}
              onClick={(event) => {
                event.target.select();
                setCopySuccess(copy(event.target.value));
              }}
            />
          </CardActions>
        )}
        {copySuccess && (
          <CardActions>
            <div className="copy-success">Copied!</div>
          </CardActions>
        )}
      </Card>
    </form>
  );
};
export default Form;
